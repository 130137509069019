import React, { useState, useEffect, useContext } from "react";
import { PatientIDContext } from "../../../Contexts";
import { getTrials } from "../../../util/reportAPI";
import ReportRow from "./ReportRow";
import TrialRow from "./TrialRow";

function PatientReportsTable(props) {
  // const [patientTrials, setPatientTrials] = useState(props.patientReports);
  const patientID = useContext(PatientIDContext);
  const [query, setQuery] = useState("");
  const search_keys=['id','title']
  const search=(data)=>{
    let filtered=data.filter(obj=>search_keys.some(key=>String(obj[key]).toLowerCase().includes(query.toLowerCase())))
    return filtered
    }
  // useEffect(() => {
  //   getTrials(patientID).then((data) => {
  //     const res = data.result;
  //     const sortedTrials = res.sort((a, b) => {
  //       const parseA = a.name.trim().split("--");
  //       const dateA = new Date(
  //         parseA[0] + "T" + parseA[1].replace(/-/g, ":") + "Z"
  //       );

  //       const parseB = b.name.trim().split("--");
  //       const dateB = new Date(
  //         parseB[0] + "T" + parseB[1].replace(/-/g, ":") + "Z"
  //       );

  //       return dateB - dateA;
  //     });
  //     setPatientTrials(sortedTrials);
  //   });
  // }, [patientID]);

  return (
    <section className="patient-table container">
    {props.patientReports && props.patientReports.length>0?<table>
      <tbody>
        <tr>
          <td colSpan="100%" style={{ height: "fit-content" }}>
            <input
              id="search-bar"
              placeholder="Search..."
              onChange={(e) => setQuery(e.target.value)}
            ></input>
          </td>
        </tr>

        <tr>
          <th>Report ID</th>
          <th>Title</th>
          <th>Trials</th>
          <th>Angles</th>
          <th>Date</th>
        </tr>
        {search(props.patientReports).map((report) => (
        <ReportRow key={report.id} report={report} />
      ))}
      </tbody>
    </table>:<div style={{'text-align':'center','margin-top':'30px','color':'gray','font-size':'1.2rem'}}>No data available.</div>}
    </section>
  );
}

export default PatientReportsTable;
