import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../store/user-slice";
import "../../../dist/assets/stylesheets/css/account.css";
import { Icon } from '@iconify/react';
import { notificationActions } from '../../store/notification-slice';
import { changePassword, updateUser } from "../../util/userApi";
function AccountPage() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.info);
  const [userInfoCopy,setUserInfoCopy]= useState(userInfo)
  useEffect(()=>{
    setUserInfoCopy(userInfo)
  },[userInfo])  
  useEffect(() => {
    dispatch(fetchUserData());
  }, []);
  const [showPasswordChagne, setShowPasswordChange] = useState(false);
  const [passwordForm,setPasswordForm]=useState({currentPw:'',newPw:''})
  const submitPassword=(e)=>{
    e.preventDefault()
    if (!passwordForm.currentPw||!passwordForm.newPw){
        return;
    }
    changePassword(passwordForm).then(res=>dispatch(notificationActions.setNotification({show:true,status:'success',message:'Updated password successfully!'}))).catch(e=>dispatch(notificationActions.setNotification({show:true,status:'error',message:e.response.data.reason})))
  }
  const submitUpdate=()=>{
    updateUser(userInfoCopy).then(res=>dispatch(notificationActions.setNotification({show:true,status:'success',message:'Updated successfully!'}))).catch(e=>dispatch(notificationActions.setNotification({show:true,status:'error',message:e.response.data.reason})))
    dispatch(fetchUserData());
  }
  const handleDownload = async () => {
    try {
      const response = await fetch(`${window.SERVER_URL}/a/download_certificate`);
      // if (!response.ok) throw new Error('Network response was not ok.');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'evovision.local.crt'; // Adjust the filename as needed
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  
  return (
    <div className="parent-container account-page">
      <h1 className="page-title">Account</h1>
      <span className="page-sub-title">
        Manage your account and subscription plan.
      </span>
      {userInfo&&<div className="account-info-container">
        <div className="group">
          <div className="left">
            <h3>Personal Information</h3>
            <span>We will use this information to contact you.</span>
          </div>
          <div className="right person-info">
            <div>
            <span>First Name</span>
            <input onChange={(e)=>setUserInfoCopy({...userInfoCopy,first_name:e.target.value})} defaultValue={userInfo.first_name}></input>
            </div>
            <div>
            <span>Last Name</span>
            <input onChange={(e)=>setUserInfoCopy({...userInfoCopy,last_name:e.target.value})} defaultValue={userInfo.last_name}></input>
            </div>
            <div>
            <span>Email</span>
            <input defaultValue={userInfo.email} disabled></input>
            </div>
            <div>
            <span>Phone</span>
            <input onChange={(e)=>setUserInfoCopy({...userInfoCopy,phone:e.target.value})} defaultValue={userInfo.phone}></input>
            </div>
            <button className="save" onClick={submitUpdate}>Save</button>

          </div>
        </div>
        <div className="group">
          <div className="left">
            <h3>Password</h3>
          </div>
          <div className="right">
            {showPasswordChagne ? (
              <>
                <form onSubmit={submitPassword} className='pw-form'>
                <span>Current Password</span>
                <input required onChange={(e)=>setPasswordForm({...passwordForm,currentPw:e.target.value})}></input>
                <span>New password</span>
                <input required onChange={(e)=>setPasswordForm({...passwordForm,newPw:e.target.value})}></input>
                <button className="save-pw">Save</button>
                </form>
              </>
            ) : (
              <button className="change-pw" onClick={() => setShowPasswordChange(true)}>

                Change Password
              </button>
            )}
          </div>
        </div>
        <div className="group">
          <div className="left">
            <h3>Certificate</h3>
            <span>Download it to start communicating with EvoVision</span>
          </div>
          <div className="right">
            <button onClick={handleDownload} >Download certificate</button>
          </div>
        </div>
        <div className="group">
          <div className="left">
            <h3>Subscription plan</h3>
            <span>Manage and upgrade your plan</span>
          </div>
        </div>
        <div className="group">
          <div className="left">
            <h3>Billing information</h3>
            <span>Your payment method and time usage.</span>
          </div>
          <div className="right">
                <h4>Time usage</h4>
                <span className="progress-bar">
                    <span className="progress" style={{width:(userInfo.time_used/userInfo.time_balance)*100+'%'}}></span>
                </span>
                <span>{userInfo.time_balance!=0?((userInfo.time_used/userInfo.time_balance)*100).toFixed(2)+'%':'0%'} Used ({userInfo.time_used.toFixed(2)}min out of {userInfo.time_balance}min)</span>
                <span className="add-time"><Icon icon="fluent:add-16-filled" />
Add time</span>
          </div>
        </div>
      </div>}
    </div>
  );
}

export default AccountPage;
