import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import { getUserData } from '../util/userApi'

export const fetchUserData=createAsyncThunk("user/fetchUser",async()=>{
    return getUserData()
})
const userSlice=createSlice({
    name:'user',
    initialState:{
        isLoggedIn:false,
        info: null
    },
    reducers:{
        setUserState:(state,action)=>{
            state.isLoggedIn=action.payload.isLoggedIn
            state.info=action.payload.info
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchUserData.fulfilled,(state,action)=>{
            state.info=action.payload.data
        })
    }
})
export const {setUserState} = userSlice.actions
export default userSlice 