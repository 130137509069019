import axios from 'axios'
export async function getPatients(patientId = "") {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + "/a/get-patients?patientId=" + patientId, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }) //get patients from the python api get-patients
    .then((res) => res.json()) //convert the result to json
    .catch((err) => console.log(err)); //check for errors
}
export async function deletePatient(patientId) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + "/a/delete-patient?patientId=" + patientId, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((res) => res.json());
}
export async function updatePatient(data) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + "/a/update-patient", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function getPatientSessions(patientID) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + `/a/get-sessions?patient=${patientID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}

// this function is used by outdated code
// export async function getPatientTags(patientID) {
//     return fetch(`/api/get-tags-patient?patient=${patientID}`)
//            .then(res => res.json())
//            .catch(err => console.log(err))
// }

export async function createNewPatient(data) {
  const token = localStorage.getItem("token");
  return axios.post(window.SERVER_URL + "/a/create-patient",JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}

export async function updatePatientTags(patientID, patientNotes) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + "/a/update-patient-tags", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      patient_id: patientID,
      patient_notes: patientNotes,
    }),
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
