import axios from "axios";
export async function getTrials(patientID) {
  const token = localStorage.getItem("token");
  return fetch(
    window.SERVER_URL + `/a/get-trials-by-patient?patient_id=${patientID}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function getAllTrialsByUser(){
  const token = localStorage.getItem("token");
  return fetch(
    window.SERVER_URL + `/a/get-trials-by-user`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function getReports(patientID) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + `/a/reports?patient_id=${patientID}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function getReport(reportID) {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + `/a/reports/report?report_id=${reportID}&`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function getDictMetrics(trialID) {
  const token = localStorage.getItem("token");
  return fetch(
    window.SERVER_URL + `/a/get-dict-metrics?trial_id=${trialID}&token=${token}`
  )
    .then((res) => res.json())
    .catch((err) => console.log(err));
}

export async function getTrialsSummaryReport(trialIDList) {
  const token = localStorage.getItem("token");
  const trialParams = trialIDList.map((trial_id) => `trial_id=${trial_id}`);
  const url = "/a/get-trials-by-ids?".concat(trialParams.join("&"));
  return fetch(window.SERVER_URL + url + `&token=${token}`)
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export async function createReport(report) {
  const token = localStorage.getItem("token");
  return axios
    .post(
      window.SERVER_URL + `/a/create-report`,
      {
        ...report,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function updateReport(report) {
  const token = localStorage.getItem("token");
  return axios
    .put(
      window.SERVER_URL + `/a/update-report`,
      {
        ...report,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data)
   
}
export async function deleteReport(reportID) {
  const token = localStorage.getItem("token");
  return axios
    .delete(
      window.SERVER_URL + `/a/delete-report?report_id=`+reportID,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data).catch(e=>console.log(e))
   
}