import React, { useState, useEffect, useCallback, useMemo } from "react";

import "../../../../dist/assets/stylesheets/css/reviewPatient.css";
import { getMetrics, getValuesMetrics } from "../../../util/metricsAPI";
import { Icon } from "@iconify/react";
import CanvasJSReact from "@canvasjs/react-charts";
import { useDispatch } from "react-redux";
import { updateCurrentTime } from "../../../store/video-slice";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function DisplayCharts(props) {
  const { trial, metricName } = props;
  const metricType = "angles";
  const dispatch = useDispatch()
  // const [graphData, setGraphData] = useState({
  //   linePoints: [],
  //   histogramPoints: [],
  // });
  const cursor_thickness_denom=props.inComparePage?500:800
  const [cursorThickness, setCursorThickness] = useState(0.1);
  const [options, setOptions] = useState({
    zoomEnabled: true,
    exportEnabled: true,
    animationEnabled: true,
    responsive: true,
    axisY: {
      title: "Degrees",
    },
    axisX: {
      title: "Time(s)",
      stripLines: [
        {
          startValue: 0,
          endValue: 0.1,
          color: "#000000",
        },
      ],
    },
    rangeChanged: function (e) {
      handleRangeChange(e, this);
    },
    data: [],
  });
  const handleRangeChange = (e, obj) => {
    if (
      e.axisX[0].viewportMaximum == null &&
      e.axisX[0].viewportMinimum == null
    ) {
      let x_range =
        obj.data[0].dataPoints[obj.data[0].dataPoints.length - 1].x -
        obj.data[0].dataPoints[0].x;
      return setCursorThickness(x_range / cursor_thickness_denom);
    }
    let x_range = e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum;
    let new_thickness = x_range / cursor_thickness_denom;

    setCursorThickness(new_thickness);
  };
  useEffect(() => {
    if (props.videoRef.current)
      setOptions({
        ...options,
        axisX: {
          title: "Time(s)",
          stripLines: [
            {
              startValue: props.currentTime,
              endValue: props.currentTime + cursorThickness,
              color: "#000000",
            },
          ],
        },
      });
  }, [props.currentTime, cursorThickness]);

  const dataClickHandler = (e) => {
    props.videoRef.current.currentTime = e.dataPoint.x;
    props.setCurrentTime(e.dataPoint.x);
    dispatch(updateCurrentTime(e.dataPoint.x))
  };
  const fetchMetrics = useCallback(() => {
    getValuesMetrics(trial, metricType, metricName).then((res) => {
      console.log('fetch metrics', res)
      if (res) {
      // if (res && res.values && res.values.some(value => value[0] !== null)) {
        console.log(metricName, 'is being fetched')
        console.log('and the values are', res.values)
        console.log(props.videoRef.current.duration)
        const denominator = metricType === "angles" ? (res.values.length / props.videoRef.current.duration) : 1;
        const linePoints = (res.values || []).map((data, idx) => ({
          x: idx / denominator,
          y: data,
        }));
        // const histogramPoints = (res.values || []).map((data) => ({ x: data }));
        // setGraphData({ linePoints, histogramPoints });
        linePoints.forEach((obj, index) => {
          obj.y = obj.y[0];
        });
        let data = [
          {
            type: "line",
            dataPoints: linePoints,
            click: dataClickHandler,
            color: "#63C7CF",
          },
        ];
        
        setOptions({ ...options, data: data });
        
        let x_range =
          data[0].dataPoints[data[0].dataPoints.length - 1].x -
          data[0].dataPoints[0].x;
        setCursorThickness(x_range / cursor_thickness_denom);
      }
  });
  }, [trial, metricType, metricName]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    console.log(metricName, options.data);
  }, [options.data]);

  return (
    <div style={{padding:"20px 0"}}>
    {/* <div style={{ padding: options.data.length > 0 ? "20px 0" : "0" }}> */}
      {/* {options.data.length > 0 ? ( */}
        <figure>
          <div className="graph-title">
            <span className="drag-container" {...props.providedDragHandleProps}>
              <Icon icon="akar-icons:drag-vertical" />
            </span>
            {metricName}
            <button className="delete-button" onClick={() => props.deleteChart()}>
              Delete
            </button>
          </div>
          {trial ? (
            <CanvasJSChart containerProps={{ width: '100%', maxWidth: '100%', backgroundColor: '#000000', height: '100%' }} options={options} />
          ) : null}
        </figure>
      {/* ) : null} */}
    </div>
  );
}

export default DisplayCharts;
