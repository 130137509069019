import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import "../../../dist/assets/stylesheets/css/newpatient.css";
import { updatePatient } from "../../util/patientsApi";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { fetchPatients } from "../../store/patients-slice.js";
import { fetchPatient } from "../../store/patient-slice";
import { notificationActions } from "../../store/notification-slice";
function UpdatePatientModal(props) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: props.data.patient_name,
      condition: props.data.patient_condition,
      notes: props.data.patient_notes,
      email: props.data.patient_email,
      height: props.data.patient_height,
      weight: props.data.patient_weight,
      gender: props.data.patient_gender,
      phone: props.data.patient_phone,
    },
  });
  const { values, setFieldValue } = formik;
  // CreatableSelect custom styles
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#ececec",
      border: "none",
      borderRadius: "0",
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const token = localStorage.getItem("token");
    console.log("values ", values, props.data.patient_id);
    updatePatient({
      ...values,
      patient_id: props.data.patient_id,
      token: token,
    })
      .then((res) => {
        props.handleClose();
        dispatch(fetchPatient(props.data.patient_id));
        dispatch(
          notificationActions.setNotification({
            show: true,
            message: "Updated patient successfully.",
            status:'success'
          })
        );
      })
      .catch((e) =>{
        dispatch(
          notificationActions.setNotification({
            show: true,
            message: "Something wrong happened. Please try again.",
            status:'error'
          }))
      });
  };
  return (
    <div className="patient modal-container">
      <div id="patient-modal">
        <Icon
          onClick={props.handleClose}
          className="close-btn"
          icon="ic:round-close"
        />
        <div className="modal-header">
          <h3>Edit patient</h3>
          <span>Enter patient information.</span>
        </div>
        <form id="new-patient-form" onSubmit={handleSubmit}>
          <div className="form-group span3">
            <label required>Full Name</label>
            <input
              required={true}
              onChange={(e) => setFieldValue("name", e.target.value)}
              defaultValue={props.data.patient_name}
              className="new-patient-input"
            />
          </div>
          <div className="form-group">
            <label>Gender </label>
            <input
              onChange={(e) => setFieldValue("gender", e.target.value)}
              defaultValue={props.data.patient_gender}
              className="new-patient-input"
            />
          </div>
          <div className="form-group span2">
            <label required>Email </label>
            <input
              required
              onChange={(e) => setFieldValue("email", e.target.value)}
              defaultValue={props.data.patient_email}
              className="new-patient-input"
            />
          </div>
          <div className="form-group span2">
            <label>Phone </label>
            <input
              onChange={(e) => setFieldValue("phone", e.target.value)}
              defaultValue={props.data.patient_phone}
              className="new-patient-input"
            />
          </div>

          <div className="form-group span2">
            <label>Height </label>
            <input
              onChange={(e) => setFieldValue("height", e.target.value)}
              defaultValue={props.data.patient_height}
              className="new-patient-input"
            />
          </div>
          <div className="form-group span2">
            <label>Weight </label>
            <input
              onChange={(e) => setFieldValue("weight", e.target.value)}
              defaultValue={props.data.patient_weight}
              className="new-patient-input"
            />
          </div>
          <div className="form-group span4">
            <label>Condition </label>
            <input
              onChange={(e) => setFieldValue("condition", e.target.value)}
              defaultValue={props.data.patient_condition}
              className="new-patient-input"
            />
          </div>
          <div className="form-group span4">
            <label>Notes </label>
            <input
              onChange={(e) => setFieldValue("notes", e.target.value)}
              defaultValue={props.data.patient_notes}
              className="new-patient-input"
            />
          </div>
          <div id="add-patient-button-container" className="span4">
          <button id="cancel" onClick={props.handleClose}>
            Cancel
          </button>
          <button className="" type="submit" >
            Update Patient
          </button>
        </div>
        </form>
       
      </div>
    </div>
  );
}

export default UpdatePatientModal;
