import React, { Component, useState } from "react";
import DisplayChartv2 from "./DisplayChartv2";
import Select from "react-select";
function VideoChartContainer(props) {
  const [videoMetaData, setVideoMetaData] = useState({
    loaded: false,
    duration: null,
  });
  const [selectedChart, setSelectedChart] = useState(props.angles[0]);
  const onMetaDataLoaded = (e) => {
    setVideoMetaData({ loaded: true, duration: e.target.duration });
  };
  return (
    <div>
      <span>{props.trial_id}</span>
      <div className="video-chart-container">
        <video controls onLoadedMetadata={onMetaDataLoaded}>
          <source
            src={window.SERVER_URL + "/a/video?trial=" + props.trial_id}
            type="video/mp4"
          ></source>
        </video>
        <div style={{width:'50%'}}>
          <Select
            onChange={(e)=>setSelectedChart(e.value)}
            defaultValue={
              props.angles.map((el) => ({ label: el, value: el }))[0]
            }
            options={props.angles.map((el) => ({ label: el, value: el }))}
          />
          {videoMetaData.loaded &&
            props.angles.map((el) => (
              <DisplayChartv2
                isSelected = {selectedChart==el}
                video_duration={videoMetaData.duration}
                trial_id={props.trial_id}
                name={el}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default VideoChartContainer;
