import {createSlice} from '@reduxjs/toolkit'
const videoCompare1Slice=createSlice({
    name:'videoCompare1',
    initialState:{
        currentTime:null,
        videoLength:null,
        isLoaded:false
    },
    reducers:{
        setLoaded:(state,action)=>{
            state.isLoaded=action.payload
        },
        updateCurrentTime:(state,action)=>{
            state.currentTime=action.payload
        },
        resetState:()=>{
            state.currentTime=null,
            state.videoLength=null,
            state.isLoaded=false
        }
    }
})
export const {updateCurrentTime}= videoCompare1Slice.actions
export default videoCompare1Slice
