import {createSlice} from '@reduxjs/toolkit'
const videoSlice=createSlice({
    name:'video',
    initialState:{
        currentTime:null,
        videoLength:null,
        isLoaded:false
    },
    reducers:{
        setLoaded:(state,action)=>{
            state.isLoaded=action.payload
        },
        updateCurrentTime:(state,action)=>{
            state.currentTime=action.payload
        }
    }
})
export const {updateCurrentTime} = videoSlice.actions
export default videoSlice
