import React, { Component, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getMetrics } from "../../../util/metricsAPI";
import { chartActions } from "../../../store/chart-slice";

function MetricDropdown(props) {
  const selected_metrics = useSelector((state) => state.chart.list_metrics);
  const [allMetrics, setAllMetrics] = useState(null);
  const dispatch = useDispatch();
  const { trial, currentTime } = props;
  useEffect(() => {
    getMetrics(trial).then((res) => {
      const options = res.angles || {};
      options.forEach((el, i) => (options[i] = { label: el, value: el }));
      console.log("metrics avaliable", options);
      setAllMetrics(options);
    });
  }, []);
  const handleChange = (e) => {
    if (e!=null)
      e = e.map((el) => el.label);
    dispatch(chartActions.updateListMetrics(e));
  };
  const formatted_selected_metrics = selected_metrics.map((el) => ({
    label: el,
    value: el,
  }));
  return (
    <div className="no-print" style={{ display: "flex", alignItems: "center" }}>
      <h4 style={{ marginRight: "20px" }}>Angle charts</h4>
      <Select
        styles={{
          control:(baseStyle,state)=>({
            ...baseStyle,
            minWidth:'200px'
          }),
          menu:(baseStyle,state)=>({
            ...baseStyle,
            zIndex:15
          })
        }}
        onChange={handleChange}
        value={formatted_selected_metrics}
        isMulti
        options={allMetrics}
      ></Select>
    </div>
  );
}

export default MetricDropdown;
