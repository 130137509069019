import React, { Component } from "react";
import "../../../dist/assets/stylesheets/css/notification.css";
import { Icon } from '@iconify/react';

function ConfirmModal(props) {
  return (
    <div className="modal-container">
      <div className="modal confirm">
        <Icon onClick={()=>props.rejectHandler()} className='close-btn' icon="iconamoon:close"/>

        <div className="icon-info-container">
            <div className="icon-container">
                <Icon icon="clarity:warning-line" />
            </div>
            <div className="info-container">
                <h4>Confirmation</h4>
                <span>{props.message}</span>
            </div>
        </div>
        <div className="btn-container">
          <button id="cancel-btn" onClick={() => props.rejectHandler()}>
            Cancel
          </button>
          <button id="confirm-btn" onClick={() => props.acceptHandler()}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
