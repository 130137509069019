import { Icon } from '@iconify/react';
import React, { Component, useState } from 'react';
import '../../../dist/assets/stylesheets/css/recordPatient.css'
import { Link } from 'react-router-dom'
import ConfirmModal from '../modals/ConfirmModal';
import GreyButton from '../GreyButton';

function CalibrationSelection(props) {
    const generateColor = (status) => {
        let color;
        switch (status) {
            case 'success':
                color = '#9FD06A';
                break;
            case 'ready':
                color = '#E7D859';
                break;
            case 'failed':
                color = '#DD6364';
                break;
            case 'uploading':
                color = '#92CEFA';
                break;
            default:
                color = '#BDBDBD';
        }
        return color;
    }
    const [confirmModal, setConfirmModal] = useState({
        show: false,
        acceptHandler: null,
    })
    const confirm = () => {
        setConfirmModal({ show: true, message: 'Are you sure you want to delete this calibration? This action cannot be undone.', acceptHandler: deleteCalibration })
    }
    const deleteCalibration = () => {

    }
    return <div className='table-container calibration-selection'>
        {confirmModal.show && <ConfirmModal message={confirmModal.message} acceptHandler={confirmModal.acceptHandler} rejectHandler={() => setConfirmModal({ show: false })} />}
        <table>
            <thead>
                <tr>
                    <th>Calib ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <th colSpan="4">
                        <Link to='/calibration' style={{ display: "block", width: "100%" }}>
                            {/* <button className='record-btn'>
                            <Icon icon="fluent:record-48-regular" />
                            Record new calibration
                        </button> */}
                            <GreyButton title="Add new calibration" width="100%" />
                        </Link>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((el, i) =>
                    <tr
                        key={el.id}
                        className={props.selected == el.id ? 'selected' : ''}
                        onClick={(e) => { props.selectCalibration(el.id) }}
                    >
                        <td>{el.id}</td>
                        <td>{el.name.split(' ').slice(1).join(' ')}</td>
                        <td>
                            <span style={{
                                height: '10px',
                                width: '10px',
                                backgroundColor: generateColor(el.status),
                                borderRadius: '50%',
                                display: 'inline-block',
                                marginRight: '3px',
                            }}></span>
                            {el.status}
                        </td>
                        <td className='action'>
                            {/* <Icon icon="carbon:view" /> */}
                            {(el.status == 'success' || el.status == 'ready') && <span className='svg-btn view-btn' onClick={(e) => { e.stopPropagation(); props.selectCalibrationView(el.id) }}><Icon icon="fluent:eye-24-regular" />View</span>}
                            {/* <span className='svg-btn delete-btn' onClick={confirm}><Icon icon="ant-design:delete-outlined" />Delete</span> */}
                        </td>
                    </tr>)}
            </tbody>
        </table>
        {/* <Link to='/calibration' style={{ display: 'inline-block', float: 'right' }}>
            <button className='record-btn'>
                <Icon icon="fluent:record-48-regular" />
                Record new calibration
            </button>
        </Link> */}
    </div >;
}

export default CalibrationSelection;