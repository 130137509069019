import React, { useState, useEffect, createContext } from "react";
import Select from "react-select";
import { getTrialType, getCalibrationVideos } from "../../util/recordingAPI";
import BackButton from "../BackButton";
import RecordAssessmentContainer from "./assessments/RecordAssessmentContainer";
// import RecordSettings from './RecordSettings';
// import RecordVideo from './video/RecordVideo';
import "../../../dist/assets/stylesheets/css/recordPatient.css";
import RecordAssessment from "./assessments/RecordAssessment";
import {
  useSharedAllowRecord,
  useSaveButton,
  useSharedTrialData,
  useSharedCalibrationID,
  useSharedTrialTypeList,
  useSharedList,
} from "../SharedHooks";
import { PatientIDContext } from "../../Contexts";
import { submitDimensions, checkDevice } from "../../util/recordingAPI";
import { Link, useParams,useHistory } from "react-router-dom";

function RecordPatient(props) {
    const history=useHistory()
    const origin=history.location.state
  const { patientID } = useParams();
  const [showRecordAssessment, setShowRecordAssessment] = useState(false);
  const [calibrationVideo, setCalibrationVideo] = useState(null);
  const [calibrationVideoOptions, setCalibrationVideoOptions] = useState([]);
  const [trialType, setTrialType] = useState("trial");
  const [selectedCalibrationVideo, setSelectedCalibrationVideo] =
    useState(null);
  const { allowRecord, setAllowRecord } = useSharedAllowRecord();
  const [assessmentList, setAssessmentList] = useState([]);
  const { trialData, setTrialData } = useSharedTrialData();
  const { calibrationID, setcalibrationID } = useSharedCalibrationID();

  const [showDimensions, setShowDimensions] = useState(false);
  const currentTime = new Date().toString().split(" ").slice(1, 5).join(" ");
  const title = `calibration ${currentTime}`;
  const key = title;
  const instructions =
    "Please record a new calibration video, make sure the checkboard is visible by all the cameras";

  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [activeComponent, setActiveComponent] = useState("temp");


  useEffect(() => {
    getCalibrationVideos().then((res) => {
      const options = res.result.map((data) => ({
        value: data.id,
        label: data.name + " -- " + data.status,
      }));
      setCalibrationVideoOptions(options);
    });
  }, []);


  useEffect(() => {
    checkDevice()
      .then((res) => {
        console.log("recording", res);
        if (res.status != "success") {
          alert(
            "No recording device detected on local network. Please check it is powered on and on the same wifi."
          );
        }
      })
      .catch((err) => {
        alert(
          "No recording device detected on local network. Please check it is powered on and on the same wifi."
        );
      });
  }, []);

  // const handleRecordCalibration = (option) => {
  //   setShowDimensions(false);
  //   setActiveComponent("form");
  //   document.querySelector(".blue-button-calibration").disabled = true;
  //   const button = document.querySelector(".blue-button-calibration");
  //   button.setAttribute("class", "grey-button");
  //   trialData[title] = { id: "", notes: "" };
  //   setTrialData(trialData);
  //   console.log(allowRecord);
  //   setShowRecordAssessment(true);
  //   setCalibrationVideo(option.value);
  //   setAssessmentList(
  //     assessmentList.concat(
  //       <RecordAssessment key={key} title={title} instructions={instructions} />
  //     )
  //   );
  //   /*submitDimensions(length, width).then(res => {console.log(res)});*/
  //   window.board = { length: length, width: width };
  // };

  const pushUrl = `/setup/${patientID}`;
  return (
    <PatientIDContext.Provider value={patientID}>
      <div className="parent-container">
        <BackButton url={origin?.url||'/recordings/new-recording?'} name = {'New Recording' }/>
          <section className="container">
            <RecordAssessmentContainer trialType={trialType} />
          </section>
        {/* )} */}
      </div>
    </PatientIDContext.Provider>
  );
}

export default RecordPatient;
