import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { PatientIDContext } from '../../../Contexts';
import '../../../../dist/assets/stylesheets/css/patients.css';
import { deletePatient, getPatients } from '../../../util/patientsApi';
import PatientTrialsTable from './PatientTrialsTable';
import logo from '../../logo.PNG';
import { IoIosArrowBack } from 'react-icons/io';
import UpdatePatientModal from '../UpdatePatientModal';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchPatient, patientActions} from '../../../store/patient-slice'
import PatientInfoContainer from './PatientInfoContainer';
import {useHistory} from 'react-router-dom'
import { notificationActions } from '../../../store/notification-slice';
import {useParams} from 'react-router-dom'
import Spinner from '../../decorations/Spinner';
import ConfirmModal from '../../modals/ConfirmModal';
import { getReports, getTrials } from '../../../util/reportAPI';
import PatientReportsTable from './PatientReportsTable';
import { useQuery } from '../../SharedHooks';
function PatientDashboard(props) {
    const dispatch=useDispatch()
    const history=useHistory()
    const {patientID}=useParams()
    const query=useQuery()
    // const [patientInfo,setPatientInfo]=useState(null)
    const patientInfo=useSelector(state=>state.patient.patientInfo)
    // const [patientInfo, setPatientInfo] = useState({
    //     patient_name:"",
    //     patient_condition: "",
    //     patient_notes: "" ,
    //     patient_id: "",
    // });
    // const { patient_name, patient_condition, patient_notes, patient_id, patient_email,patient_weight,patient_height,patient_phone } = patientInfo?patientInfo:null;
    const [activeTab,setActiveTab]=useState(query.get('activeTab')||'trials')
    const [showUpdateModal,setShowUpdateModal]=useState(false)
    const [patientTrials,setPatientTrials]=useState([])
    const [patientReports,setPatientReports]=useState([])
    useEffect(()=>{
        history.push('?activeTab='+activeTab)
    },[activeTab])
    useEffect(() => {
        // getPatients(patientID)
        //     .then(res => {
        //         setPatientInfo(res[0])
        //         // console.log(patientID)
        //         // window.testvar = res;
        //         // const patientInfo = res.filter(data => data.patient_id == patientID)[0];
        //         // console.log(patientInfo);
        //         // dispatch(patientActions.setPatient(res[0]))
        //     })
        dispatch(fetchPatient(patientID))
    }, []);
    useEffect(() => {
        getTrials(patientID).then((data) => {
          const res = data.result;
          const sortedTrials = res.sort((a, b) => {
            const parseA = a.name.trim().split("--");
            const dateA = new Date(
              parseA[0] + "T" + parseA[1].replace(/-/g, ":") + "Z"
            );
    
            const parseB = b.name.trim().split("--");
            const dateB = new Date(
              parseB[0] + "T" + parseB[1].replace(/-/g, ":") + "Z"
            );
    
            return dateB - dateA;
          });
          setPatientTrials(sortedTrials);
        });
        getReports(patientID).then(res=>setPatientReports(res.data))
      }, [patientID]);
    const [confirmModal,setShowConfirmModal]=useState({
        show:false,
        acceptHandler:null,
    })
    const confirm=()=>{
        setShowConfirmModal({show:true,acceptHandler:deletePatientHandler,message:'Are you sure you want to delete this patient? This action cannot be undone.'})
    }
    const deletePatientHandler= async ()=>{
        try{
        await deletePatient(patientID)
        dispatch(notificationActions.setNotification({show:true,message:'Deleted patient successfully',status:'success'}))
        history.push('/patients')
        }
        catch(e){
            console.log(e)
            dispatch(notificationActions.setNotification({show:true,message:'Something wrong happened. Please try again.',status:'error'}))
        }
    }
    return(patientInfo?<div className="parent-container">
            <nav className="container">
                <Link to={'/patients'}>
                    <button className=" back-btn">
                        <IoIosArrowBack/>
                        Patients List
                    </button>
                </Link>
                {/* <div className="patient-info"> */}
                {/* <h3>Patient Information</h3> */}
                {/* <p><b>Name: </b> <span className="patient-name">{patient_name}</span> <b>Condition: </b><span className="patient-condition">{patient_condition}</span> <b>Notes: </b> <span className="patient-notes">{patient_notes}</span></p> */}
                {/* </div> */}
                {/* <img src={logo} alt="Logo" style={{ width: '180px', height: '60px' , marginBottom: "0px"}} /> */}
            </nav>      
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <h1 className='page-title'>Patient Dashboard</h1>
                <button className='delete-button-trash' onClick={confirm}>
                    <Icon icon="ant-design:delete-outlined" />
                    Delete Patient
                </button>
            </div>
            <nav id="dashboard-button-container" className="container">
                {/* <Link to={`/record_patient/${patientID}`} className="blue-button">
                    <button className="blue-button">Record</button>
                </Link>
                <Link to={`/report_patient/${patientID}`} className="blue-button">
                    <button className="blue-button">Create Report</button>
                </Link>
                <Link to={`/review_patient/${patientID}/0`} className="blue-button">
                    <button className="blue-button">Review Trial</button>
                </Link>
                 */}
            </nav>
            {/* <button onClick={confirm}>Delete Patient</button> */}
            {confirmModal.show&&<ConfirmModal message={confirmModal.message} rejectHandler={()=>setShowConfirmModal({...confirmModal,show:false})} acceptHandler={confirmModal.acceptHandler} />}
            {patientInfo&&<PatientInfoContainer patientInfo={patientInfo}/>}
            <div style={{display:'flex',justifyContent:'end',marginTop:'20px'}}>
            <Link to={"/patients/report_patient/"+patientID}>
                <button className='report-btn'>
                    <Icon icon="mdi:report-box-plus-outline" />
                    Create Report</button>
                </Link>
                <Link to={"/patients/compare-trials/"+patientID}>
                <button className='report-btn'>
                <Icon icon="octicon:git-compare-24" />
                Compare Trials</button>
                </Link>
                <Link to={"/recordings/new-recording?selectedPatient="+patientID}>
                <button className='record-btn'>
                <Icon icon="fluent:record-48-regular" />
                Record New Trial</button>
                </Link>
                

                
                    
            </div>
            <div className='trials-reports-container'><span onClick={()=>setActiveTab('trials')} className={activeTab=='trials'&&'active'} id='trials'>Trials</span><span className={activeTab=='reports'&&'active'} id='reports' onClick={()=>setActiveTab('reports')}>Reports</span></div>
            {activeTab=='trials'&&<PatientIDContext.Provider value={patientID}>
                <PatientTrialsTable patientTrials={patientTrials}/>
            </PatientIDContext.Provider>}
            {activeTab=='reports'&&<PatientIDContext.Provider value={patientID}>
                <PatientReportsTable patientReports={patientReports}/>
            </PatientIDContext.Provider>}
            
        </div>
    :<Spinner/>)
}

export default PatientDashboard;
