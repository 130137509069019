import React,{useState} from "react";
import "../../../dist/assets/stylesheets/css/patients.css";
import AddNewPatientButton from "./AddNewPatientButton";
import LogOutButton from "./LogOutButton";
import PatientsList from "./PatientsList";
import "../../../dist/assets/stylesheets/css/patients.css";
import { BiLogOut } from "react-icons/bi";
import AddNewPatientModal from "./AddNewPatientModal";
import { AiFillPlusSquare, AiOutlinePlusCircle } from 'react-icons/ai';

function PatientsPage(props) {
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  return (
    <>
      {/* <LogOutButton /> */}

      <div className="parent-container">
        <div className="container" style={{display:'flex',justifyContent:'space-between'}}>
            <h1 className="page-title">
            Patients
            </h1>
            <nav className="container">
                <button className="add-btn" onClick={()=>setShowAddPatientModal(true)}>
                  <AiOutlinePlusCircle style={{ fontSize: 20 }} />
                  <span style={{ marginLeft: "10px" }}>Add New Patient</span>
                </button>
            </nav>
        </div>
        <PatientsList />
        {showAddPatientModal && <AddNewPatientModal handleClose={()=>setShowAddPatientModal(false)} />}
      </div>
    </>
  );
}

export default PatientsPage;
