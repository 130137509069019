import React, { useState, useEffect, useCallback } from 'react';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Brush, ResponsiveContainer } from 'recharts';
import MetricNameDropdown from '../metricDropdowns/MetricNameDropdown';
import MetricTypeDropdown from '../metricDropdowns/MetricTypeDropdown';
import { getMetrics, getValuesMetrics } from '../../../util/metricsAPI';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function ReviewChart(props) {
  const { trial } = props;
  const [metricKeys, setMetricKeys] = useState({});
  const [metricType, setMetricType] = useState("");
  const [metricName, setMetricName] = useState("");
  const [graphData, setGraphData] = useState({
    linePoints: [],
    histogramPoints: []
  });
  const [options,setOptions]=useState({zoomEnabled: true,
    animationEnabled: true,
    axisY:{
      title:'Degrees'
    },
    axisX:{
      title:'Time(s)'
    },
    data: []
  })
  const fetchMetrics = useCallback(() => {
    if (!trial) return;
    getMetrics(trial)
      .then(res => {
        const options = res || {};
        setMetricKeys(options)
      })
  }, [trial]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  useEffect(() => {
    if (!trial || !metricName) return;
    
    getValuesMetrics(trial, metricType, metricName)
      .then((res) => {
        // keeping fps = 85 for now
        const denominator = metricType === "angles" ? 85 : 1;
        if(res && res.values) {
          const linePoints = (res.values || []).map((data, idx) => ({x: idx / denominator, y: data}));
          const histogramPoints = (res.values || []).map(data => ({x: data}));
          setGraphData({linePoints, histogramPoints});
          linePoints.forEach((obj,index) => {
            obj.y=obj.y[0]
          });
          let data=[{type:'line',dataPoints:linePoints}]
          setOptions({...options,data:data})
        }
      });
  }, [trial, metricType, metricName]);

  const handleMetricTypeChange = useCallback((value) => {
    setMetricType(value);
    setMetricName('');
  }, []);

  const handleMetricNameChange = useCallback((value) => {
    setMetricName(value);
    props.handleMetricNameChange(value)
  }, []);

  return (
    <figure>
      <div className="dropdowns">
        <MetricTypeDropdown
          metricKeys={metricKeys}
          handleChange={handleMetricTypeChange}
        />
        <MetricNameDropdown
          metricNames={metricKeys[metricType]}
          handleChange={handleMetricNameChange}
        />
      </div>
      {trial && metricName && (
        <div className="review-chart">
          <CanvasJSChart containerProps={{ width: '100%', height: '100%' }}  options = {options} 
				//  onRef={ref => this.chart = ref}
			/>
          {/* <ResponsiveContainer width="100%" height={300}>
            <LineChart data={graphData.linePoints}>
              <CartesianGrid />
              <XAxis dataKey='x'
                tickFormatter={(value) => (value ? Number(value).toFixed(2) : '0')}
                label={{ value: "Time", position: "insideBottom" }} />
              <YAxis label={{ value: "Degrees", angle: -90, position: 'insideLeft' }} />
              <Tooltip formatter={(value) => (value ? Number(value).toFixed(2) : '0')} />
              <Line type="monotone" dataKey="y" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Brush dataKey='w' height={30} stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer> */}
        </div>
      )}
    </figure>
  );
}

export default React.memo(ReviewChart);
