import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import { getPatients } from '../util/patientsApi'
export const fetchPatient=createAsyncThunk("patient/fetchPatient",async (patientId)=>{
    return getPatients(patientId)
})
const patientSlice = createSlice({
    name:'patient',
    initialState:{
        patientInfo:null,
        loading:false
    },
    reducers:{
        setPatient:(state,action)=>{
            state.patientInfo=action.payload
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchPatient.pending,(state,action)=>{
            state.loading=true
        }),
        builder.addCase(fetchPatient.fulfilled,(state,action)=>{
            state.loading=false
            state.patientInfo=action.payload[0]
        }),
        builder.addCase(fetchPatient.rejected,(state,action)=>{
            state.loading=false
        })
    }
})
export const patientActions= patientSlice.actions
export default patientSlice