import React, { useState, useEffect, useContext, useRef } from 'react';
import { PatientIDContext } from '../../../Contexts';
import { clearRecording, pauseRecording, resumeRecording, startRecording, stopRecording } from '../../../util/recordingAPI';
import { useSharedAllowRecord, useSharedCameraStream, useSharedCalibrationID, useSharedReplayAssessment, useFilename, useSaveButton, useSharedResolution, useSharedTrialData } from '../../SharedHooks';
import StreamCamera from '../video/StreamCamera';
import RecordPatient from '../RecordPatient';
import TailSpin from '../../../../node_modules/react-loader-spinner/dist/loader/TailSpin'


function DisplayButtons(props) {
    const { title, actions, setActions } = props;
    const { record, resumeRecord } = actions;
    const patientID = useContext(PatientIDContext);
    const [filename, setFilename] = useState("");
    const { cameraStream, setcameraStream } = useSharedCameraStream();
    const { SaveButton, setSaveButton } = useSaveButton();
    const { trialData, setTrialData } = useSharedTrialData();
    const { allowRecord, setAllowRecord } = useSharedAllowRecord();
    const { resolution } = useSharedResolution();
    const { setReplayAssessment } = useSharedReplayAssessment();
    const { calibrationID, setcalibrationID } = useSharedCalibrationID();
    const uploadIntervalRef = useRef(null);
    const [processState, setProcessState] = useState({
        isLoading: false,
        downloadProgress: '0/0',
        uploadProgress: '0/0',
        totalCameras: 0,
        completedDownloads: 0,
        completedUploads: 0,
        downloadDone: false,
        uploadDone: false,
      });

    const boxStyles = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        height: '150px', 
        padding: '16px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0 0 16px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      };
    const buttonStyle = {
        backgroundColor: '#80bfff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 16px',
        cursor: 'pointer',

    };

    const startProcess = async () => {
        setProcessState(prevState => ({ ...prevState, isLoading: true }));
        const totalCamerasRes = await fetch(`${window.RECORDING_URL}/api/total-cameras`).then(res => res.json());
        setProcessState(prevState => ({
          ...prevState,
          totalCameras: totalCamerasRes.tot_cams,
          downloadProgress: `0/${totalCamerasRes.tot_cams}`,
          uploadProgress: `0/${totalCamerasRes.tot_cams}`,
        }));
    
        // Start the download process and then the upload process
        startDownloadProcess(totalCamerasRes.tot_cams);
      };
    
      const startDownloadProcess = async (totalCameras) => {
        // Initiate the download process without waiting for it to complete
        fetch(`${window.RECORDING_URL}/api/finish-session`).then(response => {
          // Even if you don't wait here, ensure basic error handling
          console.log('Download session initiated');
        }).catch(error => {
          console.error('Error initiating download session:', error);
        });
      
        // Immediately start polling for download progress
        const interval = setInterval(() => {
          fetch(`${window.RECORDING_URL}/api/update-download`)
            .then(response => response.json())
            .then(data => {
              const { downloaded } = data;
      
              setProcessState(prevState => ({
                ...prevState,
                completedDownloads: downloaded,
                downloadProgress: `${downloaded}/${totalCameras}`,
              }));
      
              if (downloaded >= totalCameras) {
                clearInterval(interval);
                setProcessState(prevState => ({
                  ...prevState,
                  downloadDone: true,
                  downloadProgress: 'Done',
                }));
      
              }
            })
            .catch(error => {
              console.error('Error fetching download progress:', error);
              clearInterval(interval); // Stop polling if there's an error
            });
        }, 3000); // Adjust the polling interval as needed
      };
      
    
      const startUploadProcess = (totalCameras) => {
        // Clear any existing interval to prevent duplicates
        if (uploadIntervalRef.current) {
          clearInterval(uploadIntervalRef.current);
        }
      
        uploadIntervalRef.current = setInterval(() => {
          fetch(`${window.RECORDING_URL}/api/update-upload`)
            .then(response => response.json())
            .then(data => {
              const { trial_id, uploaded, total} = data;
              // data from the raspberry pi is ={'trial_id': trial_id, 'uploaded': count, 'total': len(camgens)}
      
              setProcessState(prevState => ({
                ...prevState,
                completedUploads: uploaded,
                uploadProgress: `trial ${trial_id}: ${uploaded}/${totalCameras}`,
              }));
      
              if (uploaded >= totalCameras) {
                clearInterval(uploadIntervalRef.current);
                setProcessState(prevState => ({
                  ...prevState,
                  uploadDone: true,
                  uploadProgress: `${trial_id}: Done`,
                  isLoading: false,
                }));
              }
            })
            .catch(error => {
              console.error('Error fetching upload progress:', error);
              clearInterval(uploadIntervalRef.current);
            });
        }, 3000); // Adjust the polling interval as needed
      };
      
      // Clear the interval when the component unmounts
      useEffect(() => {
        return () => {
          if (uploadIntervalRef.current) {
            clearInterval(uploadIntervalRef.current);
          }
        };
      }, []);
    
      // UseEffect for handling the process click, simplified for brevity
      useEffect(() => {
        if (processState.isLoading) {
          // If downloads are done but uploads haven't started
          if (processState.downloadDone && !processState.uploadDone) {
            startUploadProcess(processState.totalCameras);
          }
        }
      }, [processState]);

    const LoadingOverlay = ({ message }) => (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          zIndex: 1050, // Make sure this is above other content
        }}>
          <div>
            <TailSpin color="#ffffff" />
            <p>{message}</p>
          </div>
        </div>
      );
      

    // const fetchUpdateDownload = (totalCamerasParam) => {
    //     const interval = setInterval(() => {
    //       fetch(`${window.RECORDING_URL}/api/update-download`)
    //         .then(response => response.json())
    //         .then(data => {
    //           setCompletedDownloads(data.downloaded);
    //           setDownloadProgress(`${data.downloaded}/${totalCamerasParam}`);
    //           if (completedDownloads === totalCamerasParam) {
    //             setDownloadProgress('Done');
    //             clearInterval(interval); // Stop checking for updates
    //           }
    //         })
    //         .catch(error => console.error('Error fetching download progress:', error));
    //     }, 1500);
      
    //     return () => clearInterval(interval); // Ensure we have a way to clear this interval when needed
    //   };
    
    //   const fetchUpdateUpload = (totalCamerasParam) => {
    //     const interval = setInterval(() => {
    //       fetch(`${window.RECORDING_URL}/api/update-upload`)
    //         .then(response => response.json())
    //         .then(data => {
    //           setCompletedUploads(data.uploaded);
    //           setUploadProgress(`${data.uploaded}/${totalCamerasParam}`);
    //           if (completedUploads === totalCamerasParam) {
    //             setUploadProgress('Done');
    //             setShowPopupUpload(false)
    //             setCompletedUploads(0)
    //             setIsLoading(false)
    //             setShowPopupDownload(false)
    //             setCompletedDownloads(0)
    //             clearInterval(interval);
    //           }
    //         })
    //         .catch(error => console.error('Error fetching download progress:', error));
    //     }, 1500);
      
    //     return () => clearInterval(interval); // Ensure we have a way to clear this interval when needed
    //   };
      
    //   const fetchTotalCameras = () => {
    //     fetch(`${window.RECORDING_URL}/api/total-cameras`)
    //       .then(res => res.json())
    //       .then(data => {
    //         setDownloadProgress(`${completedDownloads}/${data.tot_cams}`);
    //         setTotalCameras(data.tot_cams);
    //         fetchUpdateDownload(data.tot_cams); // Pass totalCameras directly
    //         fetchUpdateUpload(data.tot_cams);

    //       })
    //       .catch(err => console.error(err));
    //   };
  
    const handleProcessClick = () => {

          setProcessState({
            isLoading: false,
            downloadProgress: '0/0',
            uploadProgress: '0/0',
            totalCameras: 0,
            completedDownloads: 0,
            completedUploads: 0,
            downloadDone: false,
            uploadDone: false,
        });

        startProcess();

        // setIsLoading(true);

        // setShowPopupDownload(true);
        // setShowPopupUpload(true);

        // fetchTotalCameras();

        // fetch(`${window.RECORDING_URL}/api/finish-session`)
        //   .then(response => response.json())
        //   .then(data => {
        //     if (data.status === 'success') {
        //     } else {
        //       console.error('Finish session failed:', data.reason);
        //     }
        //   })
        //   .catch(error => {
        //     console.error('Error finishing session:', error);
        //   });
      };

    const [displayButton, setDisplayButton] = useState(
            <button
                className={allowRecord ? "blue-button" : ""}
                onClick={() => {
                    if(allowRecord) toggleRecord();
                    setSaveButton(false);
                    setTrialData(trialData);
                }}
            >
                Start Recording
            </button>
    );

    const toggleRecord = () => {
        if(record) {
            setActions({record: false});
            setAllowRecord(true);
            stopRecording();
        } else {
            setAllowRecord(false);
            setActions({record: true});
            startRecording(patientID, resolution, title, calibrationID)
            .then(res => {
                setTrialData({...trialData, [title]: {...trialData[title], id: res}});
                setFilename(res);
            })
        }
    }

    const replayVideo = () => setReplayAssessment(filename);

    useEffect(() => {
        let displayButton = null;
        const { record, resumeRecord } = actions;
        if(record) {
            displayButton = (
                <div>
                    <i style={{"color": "red"}} className="far fa-stop-circle fa-2x" onClick={toggleRecord}/>
                </div>
            )
        } else if(trialData[title]) {
            if(cameraStream && trialData[title].id) {
                console.log(trialData[title].id)
                displayButton = (
                  <div className="button-container">
                        <button
                            // gray out the start button if not allowed to record
                            disabled={processState.isLoading}
                            className={allowRecord ? "blue-button" : ""}
                            // prevent user from recording multiple assessments at a once
                            onClick={() => {
                                
                                setSaveButton(false);
                                setAllowRecord(false);
                                setActions({resumeRecord: true, record: true});
                                clearRecording(trialData[title].id);
                                startRecording(patientID, resolution, title, calibrationID)
                                .then(res => {
                                    setTrialData({...trialData, [title]: {...trialData[title], id: res}});
                                    setFilename(res);
                                })

                                }
                            }
                        >
                            Redo
                        </button>
                            <button 
                              onClick={handleProcessClick} 
                              disabled={processState.isLoading || processState.downloadDone} 
                              className="blue-button"
                            >
                              Process
                            </button>
                        {/* <button onClick={handleProcessClick} disabled={processState.isLoading || processState.uploadDone} className="blue-button">Process</button> */}
                   </div>
                )
            } else if(cameraStream) {                                   
                displayButton = (
                    <button
                        className={allowRecord ? "blue-button" : ""}
                        onClick={() => {
                            if(allowRecord) toggleRecord();
                            setSaveButton(false);
                        }}
                    >
                        Start Recording
                    </button>
                )
            } else if(!cameraStream && trialData[title].id) {
                displayButton = (
                    <button 
                        className={allowRecord ? "blue-button" : ""}
                        onClick={replayVideo}
                    >
                        Play Video
                    </button>
                )
            } else if(!(cameraStream || trialData[title].id)) {
                displayButton = <button>Play Video</button>;
            }
        }
        setDisplayButton(displayButton);
    }, [actions, allowRecord, cameraStream, trialData])

    return (
        <div>
            {processState.isLoading && <LoadingOverlay message="Videos are being processed..." />}
            {displayButton}
            {/* {processState.isLoading && (
                <div>

                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 1000,
                }}> 
                Download Progress: {processState.downloadProgress}
                </div>

                <div style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 1000,
                }}>
                    Upload Progress: {processState.uploadProgress}
                </div>
                </div>
            )} */}
            {/* {showPopupDownload && (
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 1000,
                }}>
                    Download Progress: {downloadProgress}
                </div>
            )}
        {showPopupUpload && (
                <div style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '20px',
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px',
                    zIndex: 1000, // Ensure it's above other content
                }}>
                    Upload Progress: {uploadProgress}
                </div>
            )} */}
        </div>
    );

}

export default DisplayButtons;