import React, { Component, useState } from 'react';
import { Icon } from '@iconify/react';
import '../../../dist/assets/stylesheets/css/recordPatient.css'
import { startRecording, stopRecording } from '../../util/recordingAPI';
import { useSharedResolution } from '../SharedHooks';

function RecordingPanel(props) {

    return <div className={'recording-panel ' + (props.disabled ? 'disabled' : '')} >
        <span style={{ display: 'flex', alignItems: 'center' }}>
            {props.trial.finishedRecording && <Icon className="check" icon="ei:check" />}
            {/* <span className='assessment-type'>{props.trial.assessment}</span> */}
            <span className='trial-number'>CalibId: {props.trial.calibrationID} - PatientId: {props.trial.patientName}</span>
            {console.log(props.trial)}
        </span>
        {props.trial.isRecording ? <button disabled={props.disabled} className='recording-btn' onClick={props.stopRecord} >Stop Recording</button> : (props.trial.finishedRecording ? '' : <button className='recording-btn' disabled={props.disabled} onClick={props.startRecord}>Start Recording</button>)}
        <button disabled={props.trial.isRecording || props.disabled} className='delete-btn' onClick={props.removeTrial}>Delete</button>
    </div>;
}

export default RecordingPanel;;