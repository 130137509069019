import React, { useState, useEffect, useRef } from "react";
import "../../../dist/assets/stylesheets/css/videoPlayback.css";
import Box from '@mui/material/Box';

function TrialVideoPlayback(props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="flex-end" width="100%" height="auto">
      <Box width="100%" height="auto" >{props.video}</Box>
    </Box>
  );
}

export default TrialVideoPlayback;
