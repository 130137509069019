import React, { Component, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { notificationActions } from '../store/notification-slice';

function NotificationPanel() {
    const dispatch=useDispatch()
    const notification = useSelector(state=>state.notification)
    let timeoutId=null
    useEffect(()=>{                                                                                                                                                                                                                                                
        if (notification.show){
            if (timeoutId){
                console.log('clear time out')
                clearTimeout(timeoutId)
                dispatch(notificationActions.closeNotification())
            }

            timeoutId=setTimeout(()=>dispatch(notificationActions.closeNotification()),4000)
        }
    },[notification.show])

    const notificationType={
        'error':'Error!',
        'success':'Success!'
    }
    return notification.show&&<div className='notification-panel-container'>
        <div className={'notification-panel '+notification.status}>
            <Icon icon="mingcute:warning-line" />
            <span className='type'>{notificationType[notification.status]}</span>
            <span className='info'>{notification.message}</span>
            <Icon onClick={()=>dispatch(notificationActions.closeNotification())} className='close' icon="iconamoon:close-duotone" />
        </div>
    </div>;
}

export default NotificationPanel;