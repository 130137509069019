import axios from 'axios'
export async function getTrials(patientID) {
    const token = localStorage.getItem("token");
    return fetch(window.SERVER_URL + `/a/get-trials-by-patient?patient_id=${patientID}`,{
        headers:{
            Authorization:'Bearer '+token
        }
    })
           .then(res => res.json())
           .catch(err => console.log(err))
}
export async function getTrialInfo(trialID){
    const token = localStorage.getItem("token");
     return fetch(window.SERVER_URL + `/a/get-trial-by-id?trial_id=${trialID}`,{
        headers:{
            Authorization:'Bearer '+token
        }
     })
           .then(res => res.json())
           .catch(err => console.log(err))

}

export async function getTrialVideos(trialID) {
    return fetch(window.SERVER_URL + `/a/video?trial=${trialID}`)
           .then(res => res.json())
           .catch(err => console.log(err))
}

export async function createTrial(sessionID, trialTags) {
    return fetch(window.SERVER_URL + '/api/record-trial', {
        method: 'POST',
        headers:  { 'Content-Type': 'application/json' },
        body: JSON.stringify({'session_name': sessionID, 'tags': trialTags})
    })
    .then(res => res.json())
    .catch(err => console.log(err))
}

export async function deleteTrial(id) {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(window.SERVER_URL + '/a/delete-trial?id=' + id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      return response.data;
    } catch (err) {
      console.error("Error deleting trial:", err);
      throw err;
    }
  }

export async function updateTrialTags(trialID, trialTags) {
    return fetch(window.SERVER_URL + '/api/update-trial-tags', {
        method: 'POST',
        headers:  { 'Content-Type': 'application/json' },
        body: JSON.stringify({'name': trialID, 'tags': trialTags})
    })
    .then(res => res.json())
    .catch(err => console.log(err))
}
export async function processTrial(trialID) {
    const token=localStorage.getItem('token')
    return axios.post(window.SERVER_URL + `/a/process-trial?trial_id=${trialID}`,{},{
        headers:{
            Authorization:'Bearer '+token
        }
    })
           .then(res => res.data)
           .catch(err => console.log(err))
}
export async function processCalib(calibID) {
    const token=localStorage.getItem('token')
    return axios.post(window.SERVER_URL + `/a/process-calibration?calib_id=${calibID}`,{},{
        headers:{
            Authorization:'Bearer '+token
        }
    })
           .then(res => res.data)
           .catch(err => console.log(err))
}
