import React, { useState, useEffect, createContext } from "react";
import Select from "react-select";
import { getTrialType, getCalibrationVideos } from "../../util/recordingAPI";
import BackButton from "../BackButton";
import RecordAssessmentContainer from "./assessments/RecordAssessmentContainer";
// import RecordSettings from './RecordSettings';
// import RecordVideo from './video/RecordVideo';
import "../../../dist/assets/stylesheets/css/recordPatient.css";
import RecordAssessment from "./assessments/RecordAssessment";
import {
  useSharedAllowRecord,
  useSaveButton,
  useSharedTrialData,
  useSharedCalibrationID,
  useSharedTrialTypeList,
  useSharedList,
} from "../SharedHooks";
import { PatientIDContext } from "../../Contexts";
import { submitDimensions, checkDevice } from "../../util/recordingAPI";
import { Link, useParams, useHistory } from "react-router-dom";

function RecordCalibration(props) {
  const history = useHistory()
  const origin = history.location.state
  const { patientID } = useParams();
  const [trialTypeOptions, setTrialTypeOptions] = useState([]);
  const [showRecordAssessment, setShowRecordAssessment] = useState(false);
  const [calibrationVideo, setCalibrationVideo] = useState(null);
  const [calibrationVideoOptions, setCalibrationVideoOptions] = useState([]);
  const [trialType, setTrialType] = useState("trial");
  const [selectedCalibrationVideo, setSelectedCalibrationVideo] =
    useState(null);
  const { allowRecord, setAllowRecord } = useSharedAllowRecord();
  const [assessmentList, setAssessmentList] = useState([]);
  const { trialData, setTrialData } = useSharedTrialData();
  const { calibrationID, setcalibrationID } = useSharedCalibrationID();
  const [actions, setActions] = useState({
    record: false,
    resumeRecord: false,
  });
  const [showDimensions, setShowDimensions] = useState(false);
  // const currentTime = new Date().getTime();
  const currentTime = new Date().toString().split(" ").slice(1, 5).join(" ");
  // const title = `${currentTime}`;
  const title = `calibration ${currentTime}`;
  const key = title;
  const instructions =
    "Please record a new calibration video, make sure the checkboard is visible by all the cameras";

  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [activeComponent, setActiveComponent] = useState("temp");

  // initialize the Trial Type dropdown bar options
  // useEffect(() => {
  //     getTrialType()
  //         .then(res => {
  //             const options = res.map(data => ({value: data, label: data}));
  //             setTrialTypeOptions(options);
  //         });
  // }, [])

  useEffect(() => {
    getCalibrationVideos().then((res) => {
      const options = res.result.map((data) => ({
        value: data.id,
        label: data.name + " -- " + data.status,
      }));
      setCalibrationVideoOptions(options);
    });
  }, []);

  useEffect(() => {
    console.log("calibration ID changed : ", calibrationID);
    console.log("Dimensions were changed: ", length, width);
  }, [calibrationID, length, width]);

  useEffect(() => {
    checkDevice()
      .then((res) => {
        console.log("recording", res);
        if (res.status != "success") {
          alert(
            "No recording device detected on local network. Please check it is powered on and on the same wifi."
          );
        }
      })
      .catch((err) => {
        alert(
          "No recording device detected on local network. Please check it is powered on and on the same wifi."
        );
      });
  }, []);

  // for the dropdown styling
  const additionalStyles = {
    container: (provided) => ({
      ...provided,
      margin: "0 10px 0 0",
    }),
  };

  const handleCalibrationSelection = (option) => {
    setActiveComponent("select");
    setSelectedCalibrationVideo(option);
    setCalibrationVideo(option);
    setcalibrationID(option.value);

    const button = document.querySelector(".blue-button-calibration");
    button.setAttribute("class", "grey-button");
    console.log(calibrationID);
  };

  const handleRecordCalibration = (option) => {
    setShowDimensions(false);
    setActiveComponent("form");
    document.querySelector(".blue-button-calibration").disabled = true;
    const button = document.querySelector(".blue-button-calibration");
    button.setAttribute("class", "grey-button");
    trialData[title] = { id: "", notes: "" };
    setTrialData(trialData);
    setShowRecordAssessment(true);
    setCalibrationVideo(option.value);
    setAssessmentList(
      assessmentList.concat(
        <RecordAssessment key={key} title={title} instructions={instructions} />
      )
    );
    /*submitDimensions(length, width).then(res => {console.log(res)});*/
    window.board = { length: length, width: width };
  };

  const pushUrl = `/setup/${patientID}`;
  return (
    <PatientIDContext.Provider value={patientID}>
      <div className="parent-container">
        <BackButton url={origin?.url || '/recordings/new-recording?'} name={'New Recording'} />
        <div className="container-flex">
          <button
            className="blue-button-calibration"
            onClick={() => {
              setShowDimensions(true);
              setActiveComponent("form");
            }}
            disabled={activeComponent === "select"}
          >
            Record Calibration
          </button>
          {/* onClick = {handleRecordCalibration}*/}
          &nbsp;&nbsp;
          <div className="or-text">OR</div>
          &nbsp;&nbsp;
          <Select
            className="select-dropdown"
            placeholder="Select existing calibration video"
            styles={additionalStyles}
            value={selectedCalibrationVideo}
            options={calibrationVideoOptions}
            onChange={handleCalibrationSelection}
            isDisabled={activeComponent === "form"}
          />
          <Link style={{ marginLeft: "auto" }} to={pushUrl}>
            <button className="blue-button-calibration">Setup GoPro</button>
          </Link>
        </div>
        <div className="container-calibration">
          {true && (
            <table id="assessment-table">
              {/* <RecordAssessment key={key} title={title} instructions={instructions} /> */}
              <tbody>{assessmentList}</tbody>
            </table>
          )}
        </div>
        {showDimensions && activeComponent === "form" && (
          <div>
            <p className="dim-text">
              <b>Enter Checkerboard Dimensions:</b>
            </p>

            <form className={"form"} onSubmit={handleRecordCalibration}>
              <label>
                Length:
                <input
                  className={"input"}
                  type="number"
                  required
                  min="0"
                  onChange={(e) => setLength(e.target.value)}
                ></input>
              </label>

              <label>
                Width:
                <input
                  className={"input"}
                  type="number"
                  required
                  min="0"
                  onChange={(e) => setWidth(e.target.value)}
                ></input>
              </label>

              <button className={"blue-button"} type="submit">
                Submit
              </button>
            </form>
          </div>
        )}

      </div>
    </PatientIDContext.Provider>
  );
}

export default RecordCalibration;
