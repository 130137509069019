import React, { Component, useState } from "react";
import UpdatePatientModal from "../UpdatePatientModal";
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PatientInfoContainer(props) {
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  return (
    <Box borderRadius={3} padding={2} boxShadow={3} width="100%">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">
          Patient Info:
        </Typography>
        <a
          className="edit"
          onClick={() => {
            setShowUpdateModal(true);
          }}
        >
          Edit <Icon icon="material-symbols:edit" />
        </a>
        {showUpdateModal && <UpdatePatientModal handleClose={() => setShowUpdateModal(false)} data={props.patientInfo} />}
      </Box>
      <Box display="flex" justifyContent="space-between">
        {props.patientInfo.patient_name && (
          <Box>
            <Typography variant="h6">Name</Typography>
            <Typography >{props.patientInfo.patient_name}</Typography>
          </Box>
        )}
        {props.patientInfo.patient_email && (
          <Box>
            <Typography variant="h6">Gender</Typography>
            <Typography>{props.patientInfo.patient_gender}</Typography>
          </Box>
        )}
      </Box>
      <Box>
        {props.patientInfo.patient_gender && (
          <Box>
            <Typography variant="h6">Email</Typography>
            <Typography>{props.patientInfo.patient_email}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  (
    <>
      <div className="patient-info-big-container">
        <h3>Patient Information:</h3>
        <div className="patient-info-container">

          {props.patientInfo.patient_name && (
            <div>
              <span className="title">Full name </span><br />
              <span className="info">{props.patientInfo.patient_name}</span>
            </div>
          )}
          {props.patientInfo.patient_email && (
            <div>
              <span className="title">Email address </span><br />
              <span className="info">{props.patientInfo.patient_email}</span>
            </div>
          )}
          {/* {props.patientInfo.patient_phone && (
            <div>
              <span className="title">Phone number </span><br />
              <span className="info">{props.patientInfo.patient_phone}</span>
            </div>
          )} */}
          {props.patientInfo.patient_gender && (
            <div>
              <span className="title">Gender </span><br />
              <span className="info">{props.patientInfo.patient_gender}</span>
            </div>
          )}
          {/* {props.patientInfo.patient_height && (
            <div>
              <span className="title">Height </span><br />
              <span className="info">{props.patientInfo.patient_height}</span>
            </div>
          )} */}
          {/* {props.patientInfo.patient_weight && (
            <div>
              <span className="title">Weight </span><br />
              <span className="info">{props.patientInfo.patient_weight}</span>
            </div>
          )} */}
          {/* {props.patientInfo.patient_condition && (
            <div>
              <span className="title">Condition </span><br />
              <span className="info">{props.patientInfo.patient_condition}</span>
            </div>
          )} */}
          {/* {props.patientInfo.patient_notes && (
            <div>
              <span className="title">Notes</span><br />
              <span className="info">{props.patientInfo.patient_notes}</span>
            </div>
          )} */}

        </div>
        <a
          className="edit"
          onClick={() => {
            setShowUpdateModal(true);
          }}
        >
          Edit <Icon icon="material-symbols:edit" />
        </a>
      </div>
      {showUpdateModal && <UpdatePatientModal handleClose={() => setShowUpdateModal(false)} data={props.patientInfo} />}
    </>
  )
}

export default PatientInfoContainer;
