import React, { useState, useEffect,useRef,useCallback } from 'react';
import { PatientIDContext } from '../../Contexts';
import '../../../dist/assets/stylesheets/css/patients.css'
import BackButton from '../BackButton';
import ReviewChartsContainer from './charts/ReviewChartsContainer';
import TrialDropDown from './TrialDropDown';
import TrialVideoPlayback from './TrialVideoPlayback';
import { useParams, useHistory } from 'react-router-dom';
import PreviewChartsContainer from './charts/PreviewChartContainer';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatient } from '../../store/patient-slice';
import PatientInfoContainer from '../patients/patientDashboard/PatientInfoContainer';
import { getPatients } from '../../util/patientsApi';
// import { updateCurrentTime } from '../../store/video-slice';
import { updateCurrentTime } from '../../store/compare/video-compare-2-slice';
import { getTrialInfo } from '../../util/trialApi';
import TrialInfoContainer from '../patients/TrialInfoContainer';
import MetricDropdown from './metricDropdowns/MetricDropdown';
function Subcompare2(props) {
    const { patientID } = useParams();
    const dispatch=useDispatch()
    const [trialInfo,setTrialInfo]=useState(null)
    const trial_id = props.trialId;
    const [trial, setTrial] = useState(trial_id);
    const [currentTime,setCurrentTime]=useState(0)
    const [video, setVideos] = useState(null);
    const [metaDataLoaded,setMetaDataLoaded]=useState(false)
    const videoRef=useRef()
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    useEffect(()=>{
    const interval=setInterval(()=>{
      if (videoRef.current)
        setCurrentTime(videoRef.current.currentTime)
        dispatch(updateCurrentTime(videoRef.current.currentTime))
    },1000/60)
    return ()=>clearInterval(interval)
  },[])
    useEffect(()=>{
      getTrialInfo(props.trialId).then(res=>{
        setTrialInfo(res.trial)
      })
    },[props.trialId])
    useEffect(() => {
        if (props.trialId) {
        const fetchData=async()=>{
          const video = await fetch(window.SERVER_URL + "/a/video?trial=" + props.trialId);
          const videoOption = (
            <video ref={videoRef}
              className={"view"}
              key={video.url}
              controls
              onLoadedMetadata={handleLoadedMetaData}
            >
              <source src={video.url} type="video/mp4" />
            </video>
          );
          setVideos(videoOption);
        }
            setMetaDataLoaded(false)
          fetchData()
        }
      }, [props.trialId]);
    const handleLoadedMetaData=(event)=>{
        setMetaDataLoaded(true)
    }
    return (    
        <PatientIDContext.Provider value={patientID}>
            <div className="parent-container subcompare">
                <div style={{display:'flex',marginBottom:'20px',justifyContent:'space-between'}}>
                </div>
                <div style={{display:'flex'}}>
                {trialInfo&&<TrialInfoContainer trialInfo={trialInfo}/>}
                </div>
                <div className='video-preview-container'>
                    <TrialVideoPlayback video={video}/>
                </div>
                <div><MetricDropdown trial={trial}></MetricDropdown></div>
                {metaDataLoaded&&<ReviewChartsContainer inComparePage={true} videoRef={videoRef} currentTime={currentTime} trial={trial_id}/>}
            </div>
        </PatientIDContext.Provider>
    )
}

export default Subcompare2;