import React, { useState } from 'react';
import { Icon } from "@iconify/react";

function GreyButton(props) {
    const [isHovered, setIsHovered] = useState(false);

    const baseButtonStyle = {
        backgroundColor: '#F5F5F5',
        border: '1px solid #C0C0C0',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        width: props.width || 'auto',
    };

    const baseTextStyle = {
        margin: 1,
        padding: 0,
    };

    const firstLineStyle = {
        ...baseTextStyle,
        fontWeight: 'bold',
    };

    const hoverStyle = {
        ...baseButtonStyle,
        backgroundColor: '#E0E0E0'
    };

    return (
        <button
            style={isHovered ? hoverStyle : baseButtonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={props.onClick}
        >
            <span style={firstLineStyle}>
                <Icon icon="mingcute:add-fill" /> { }
                {props.title}
            </span>
        </button>
    );
}

export default GreyButton;
