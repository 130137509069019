import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useFormik } from 'formik';
import { Link, Redirect, useHistory} from 'react-router-dom';
import '../../dist/assets/stylesheets/css/signup.css';
import { SignUp } from '../util/signupAPI';
import { useisLoggedIn } from './SharedHooks';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../store/notification-slice';


function SignUpPage() {
    // const {isLoggedIn, setisLoggedIn} = useisLoggedIn();
    const formik = useFormik({
        initialValues: {
            first:"",
            last:"",
            email: "",
            password: "",
            license_key:"",
        }
    })

    const {values, setFieldValue } = formik;
    const history = useHistory()
    
    // CreatableSelect custom styles
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#ececec",
            border: "none",
            borderRadius: "0"
        })
    }

    // try using UseEffect for login
    // let handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //       let res = await SignUp({...values});
    //       if (res) {
    //         console.log(res)
    //         history.push('/login')
    //       }
    //       else {
    //         console.log(res);
    //       }
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   };

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let res = await SignUp({...values});
            let data = await res.json(); // Assuming this is correct
            console.log(data)

            if (res.ok && data.status === 'success') {
                dispatch(notificationActions.setNotification({
                    show: true,
                    status: 'success',
                    message: 'Signup successful!'
                }));
                history.push('/login')
            } else {
                dispatch(notificationActions.setNotification({
                    show: true,
                    status: 'error',
                    message: data.reason || 'Signup failed!'
                }));
            }
        } catch (err) {
            console.error(err);
            dispatch(notificationActions.setNotification({
                show: true,
                status: 'error',
                message: err.message || 'An unexpected error occurred!'
            }));
        }
    };
  
    
    return (
        <section id="signup-container">
            <header>Sign Up</header>
            <form onSubmit={handleSubmit} id="signup-form">
                <label>First Name:</label>
                <input 
                        onChange={e => setFieldValue("first", e.target.value)}
                        className="signup-input"
                />
                <label>Last Name:</label>
                <input 
                        onChange={e => setFieldValue("last", e.target.value)}
                        className="signup-input"
                />
                <label>Email:</label>
                <input 
                        onChange={e => setFieldValue("email", e.target.value)}
                        className="signup-input"
                />
                <label>Password:</label>
                <input 
                    onChange={e => setFieldValue("password", e.target.value)}
                    className="signup-input"
                />
                <label>License Key:</label>
                <input 
                    onChange={e => setFieldValue("license_key", e.target.value)}
                    className="signup-input"
                />
                <div id="signup-button-container">
                <button className="blue-button" type="submit">Sign Up</button>
                </div>
            </form>
   
        </section>
    )
}

export default SignUpPage;
