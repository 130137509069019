import React, { Component, useState } from "react";
import logo from "./logo.PNG";
import croppedlogo from './croppedlogo.png'
import "../../dist/assets/stylesheets/css/navbar.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useHistory } from 'react-router-dom'
function Navbar() {
  const history = useHistory()
  const logOut = () => {
    localStorage.removeItem('token')
    history.push('/')
  }
  const isActive = (path_name) => {
    let window_url = window.location.href
    if (window_url.includes(path_name))
      return true
    return false
  }

  return (
    <div className={"navbar"}>
      <div style={{ display: 'flex', 'justify-content': 'center' }}><img
        src={logo}
        alt="Logo"
        style={{ width: "180px", height: "60px", marginBottom: "0px" }}
      /></div>
      <div className={"menu"}>
        <span className="menu-title">MENU</span>
        <Link to="/devices" className={isActive("/devices") && "active"}>
          <Icon icon="ph:camera-light" />
          <span>Devices</span>
        </Link>
        <Link to="/calibration" className={isActive("/calibration") && "active"}>
          <Icon icon="bxs:chess" />
          <span>Calibration</span>
        </Link>
        <Link to="/record-test" className={isActive("/record-test") && "active"}>
          <Icon icon="ph:video-camera" />
          <span>Record</span>
        </Link>
        <Link to="/recordings" className={isActive("/recordings") && "active"}>
          <Icon icon="ph:video-thin" />
          <span>Recordings</span>
        </Link>
        <Link to="/patients" className={isActive("/patients") && "active"}>
          <Icon icon="fluent:people-team-28-regular" />
          <span>Analytics</span>
        </Link>
        <hr />
        <Link to="/account" className={isActive("/account") && "active"}>
          <Icon icon="codicon:account" />
          <span>Account</span>
        </Link>
        <a id='logout-btn' onClick={logOut}><Icon icon="ph:sign-out" />Log out</a>
      </div>
    </div>
  );
}

export default Navbar;
