import React, { useState, useEffect } from 'react';
import { getTrialsSummaryReport } from '../../../util/reportAPI';
import { useSharedFilterTrials } from '../../SharedHooks';
import ReportAssessment from './ReportAssessment';

function ReportAssessmentSummaryContainer() {

    const [summaryList, setSummaryList] = useState([]);
    const  { selectedTrials } = useSharedFilterTrials();

    useEffect(() => {
        getTrialsSummaryReport(selectedTrials)
            .then(res => {
                const summaryList = res.result.map(data => <ReportAssessment key={data.id} info={data}/>)
                setSummaryList(summaryList);
            });
    }, [selectedTrials])

    return (
        <section className="container">
            <span>Assessment Summary</span>
            {summaryList}
        </section>
    )
}

export default ReportAssessmentSummaryContainer;
