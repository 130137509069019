import React, { useState, useEffect, useRef, useCallback } from "react";
import { PatientIDContext } from "../../Contexts";
import "../../../dist/assets/stylesheets/css/patients.css";
import BackButton from "../BackButton";
import ReviewChartsContainer from "./charts/ReviewChartsContainer";
import TrialDropDown from "./TrialDropDown";
import TrialVideoPlayback from "./TrialVideoPlayback";
import { useParams, useHistory } from "react-router-dom";
import PreviewChartsContainer from "./charts/PreviewChartContainer";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatient } from "../../store/patient-slice";
import PatientInfoContainer from "../patients/patientDashboard/PatientInfoContainer";
import { getPatients } from "../../util/patientsApi";
import { updateCurrentTime } from "../../store/video-slice";
import { getTrialInfo } from "../../util/trialApi";
import TrialInfoContainer from "../patients/TrialInfoContainer";
import MetricDropdown from "./metricDropdowns/MetricDropdown";
import logo from "../logo.PNG";
import ConfirmModal from "../modals/ConfirmModal";
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import InfoCard from "../patients/InfoCard";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';


const DeleteButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  color: 'white',
  borderColor: '#DD6164',
  backgroundColor: '#DD6164',
  '&:hover': {
    backgroundColor: 'rgba(211, 97, 100, 0.7)',
  },
  '& svg': {
    marginRight: '5px',
    fontSize: '1.2rem',
  }
});

const PrintButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  color: 'white',
  borderColor: 'black',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  '& svg': {
    marginRight: '5px',
    fontSize: '1.2rem',
  }
});

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '60%',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function ReviewPatient(props) {
  const history = useHistory();
  const origin = history.location.state;
  const { patientID } = useParams();
  const dispatch = useDispatch();
  const [patientInfo, setPatientInfo] = useState(null);
  const [trialInfo, setTrialInfo] = useState(null);
  const params = useParams();
  const patient_id = Object.values(params)[0];
  const trial_id = Object.values(params)[1];
  const [trial, setTrial] = useState(trial_id);
  const [currentTime, setCurrentTime] = useState(0);
  const [video, setVideos] = useState(null);
  const [metaDataLoaded, setMetaDataLoaded] = useState(false);
  const [confirmModal, setShowConfirmModal] = useState({
    show: false,
    acceptHandler: null,
  });
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const location = useLocation();
  const { backUrl, backName } = location.state || { backUrl: `/patients/patient_dashboard/${patientID}`, backName: 'Dashboard' };

  const handleOpenGenerateModal = () => setOpenGenerateModal(true);
  const handleCloseGenerateModal = () => setOpenGenerateModal(false);

  const confirm = () => {
    setShowConfirmModal({
      show: true,
      acceptHandler: deleteTrialHandler,
      message:
        "Are you sure you want to delete this trial? This action cannot be undone.",
    });
  };
  const deleteTrialHandler = () => {

  }
  const handleTrialChange = (id) => {
    // navigate('asdf')
    history.replace({
      pathname: "/patients/review_patient/" + patient_id + "/" + id,
      state: origin,
    });
    setTrial(id);
  };
  const videoRef = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        setCurrentTime(currentTime);
        dispatch(updateCurrentTime(currentTime));
      }
    }, 1000 / 60);
    return () => clearInterval(interval);
  }, [videoRef, dispatch]);

  useEffect(() => {
    getPatients(patientID).then((res) => {
      setPatientInfo(res[0]);
    });
    getTrialInfo(trial_id).then((res) => {
      setTrialInfo(res.trial);
    });
  }, []);

  const handleVideoError = (e) => {
    console.error('Video loading error:', e);
    // Handle the error appropriately
  };

  useEffect(() => {
    console.log("useEffect triggered with trial:", trial);

    if (trial) {
      const fetchData = async () => {
        try {
          console.log("Fetching video URL for trial:", trial);
          const response = await fetch(window.SERVER_URL + "/a/video?trial=" + trial);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const videoUrl = await response.text();
          console.log("Received video URL:", videoUrl);

          const videoOption = (
            <video
              ref={videoRef}
              className={"view"}
              key={videoUrl}
              controls
              onLoadedMetadata={handleLoadedMetaData}
              onError={handleVideoError}
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          );

          console.log("Video JSX element created");
          setVideos(videoOption);
        } catch (error) {
          console.error("Error in fetching video:", error);
        }
      };

      fetchData();
    } else {
      console.log("Trial ID is not set.");
    }
  }, [trial]);


  // useEffect(() => {
  //   if (trial) {
  //     const fetchData = async () => {
  //       // const video = await fetch(window.SERVER_URL + "/a/video?trial=" + trial);
  //       const videoOption = (
  //         <video
  //           ref={videoRef}
  //           className={"view"}
  //           key={window.SERVER_URL + "/a/video?trial=" + trial}
  //           controls
  //           onLoadedMetadata={handleLoadedMetaData}
  //         >
  //           <source
  //             src={window.SERVER_URL + "/a/video?trial=" + trial}
  //             type="video/mp4"
  //           />
  //         </video>
  //       );
  //       console.log("setvideo");
  //       setVideos(videoOption);
  //     };
  //     fetchData();
  //   }
  // }, [trial]);

  const handleLoadedMetaData = (event) => {
    setMetaDataLoaded(true);
  };

  return (
    <PatientIDContext.Provider value={patientID}>
      <div className="parent-container">
        <Modal
          open={openGenerateModal}
          onClose={handleCloseGenerateModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Generate Report for Trial
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Modal>
        {confirmModal.show && (
          <ConfirmModal
            message={confirmModal.message}
            rejectHandler={() =>
              setShowConfirmModal({ ...confirmModal, show: false })
            }
            acceptHandler={confirmModal.acceptHandler}
          />
        )}

        <img className="printable logo" src={logo}></img>
        {/* <BackButton
          url={origin?.url || `/patients/patient_dashboard/${patientID}`}
          name={origin?.name || "Dashboard"}
        /> */}
        <Box display="flex" alignItems="center" gap={5} width="100%" mt={2} mb={2}>
          <Box>
            <BackButton url={backUrl} name={backName} />
          </Box>
          <Box display="flex" justifyContent="flex-start" gap={2}>
            <Box display="flex" alignItems="center">
              <h2>Trial</h2>
              <TrialDropDown
                handleChange={handleTrialChange}
                selectedOption={trial}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
              {/* <button className='download'><Icon icon="octicon:download-16" />Download</button> */}
              <DeleteButton onClick={confirm}>
                <Icon icon="ant-design:delete-outlined" />
                Delete Trial
              </DeleteButton>
              <PrintButton onClick={() => window.print()}>
                <Icon icon="material-symbols:print" />
                Print Page
              </PrintButton>
              <PrintButton onClick={handleOpenGenerateModal}>
                <Icon icon="carbon:document-pdf" />
                Generate Report
              </PrintButton>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
            bgcolor: 'background.paper',
          }}        >
          {/* <div className="video-preview-container">
            <TrialVideoPlayback video={video} />
            {metaDataLoaded&&<PreviewChartsContainer videoRef={videoRef} setCurrentTime={setCurrentTime} currentTime={currentTime} trial={trial}/>}
          </div> */}
          <Box display="flex" justifyContent="center" alignItems="stretch" borderRadius={3} flexBasis="70%">
            <TrialVideoPlayback video={video} />
          </Box>
          <Box display="flex" flexBasis="30%">
            {/* <Box flexBasis="50%">
              {patientInfo && <PatientInfoContainer patientInfo={patientInfo} />}
            </Box>
            <Box flexBasis="50%">
              {trialInfo && <TrialInfoContainer trialInfo={trialInfo} />}
            </Box> */}
            {patientInfo && trialInfo && <InfoCard patientInfo={patientInfo} trialInfo={trialInfo} />}
          </Box>
        </Box>
        <div>
          <MetricDropdown trial={trial}></MetricDropdown>
        </div>
        {metaDataLoaded && (
          <ReviewChartsContainer
            videoRef={videoRef}
            setCurrentTime={setCurrentTime}
            currentTime={currentTime}
            trial={trial}
          />
        )}
      </div>
    </PatientIDContext.Provider>
  );
}

export default ReviewPatient;
