import React, { useState, useEffect } from 'react';
import { BsFillRecordFill} from 'react-icons/bs';

function RecordTimer(props) {
    const { actions } = props;
    const {record, resumeRecord } = actions;

    const initialValues = {
        hour: 0,
        minute: 0,
        second: 0,
        display: "00:00:00"
    }
    
    const [time, setTime] = useState(initialValues);

    const timeUnitDisplay = timeUnit => {
        return timeUnit < 10 ? `0${timeUnit}` : `${timeUnit}`;
    }

    useEffect(() => {
        let timer;
        if(record) {
            timer = setTimeout(() => {
                let { hour, minute, second } = time;
                let carry = 0;
                second += 1;
                if(second > 59) {
                    second %= 60;
                    carry += 1;
                }

                minute += carry;
                carry = 0;
                if(minute > 59) {
                    minute %= 60;
                    carry += 1;
                }
                
                hour += carry;
                const display = `${timeUnitDisplay(hour)}:${timeUnitDisplay(minute)}:${timeUnitDisplay(second)}`;
                setTime({hour, minute, second, display});
            }, 1000);
        }
        return () => { clearTimeout(timer) }
    }, [resumeRecord, time])

    useEffect(() => {
        if(record) setTime(initialValues);
    }, [record])

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <div id="timer"> 
                <BsFillRecordFill style={{marginRight: "5px",  width: '30px', height: '30px', 
                verticalAlign: 'middle', fill: record ? 'red' : 'black'}}/> 
                {time.display}
            </div>
        </div>
      
    )
}

export default RecordTimer;