import React, { useState, useEffect, createContext } from "react";
import Select from "react-select";
import { getTrialType, getCalibrationVideos, getCalibrationVideoUrl, getCountVideos } from "../../util/recordingAPI";
import BackButton from "../BackButton";
import RecordAssessmentContainer from "./assessments/RecordAssessmentContainer";
// import RecordSettings from './RecordSettings';
// import RecordVideo from './video/RecordVideo';
import "../../../dist/assets/stylesheets/css/recordPatient.css";
import RecordAssessment from "./assessments/RecordAssessment";
import {
  useSharedAllowRecord,
  useSaveButton,
  useSharedTrialData,
  useSharedCalibrationID,
  useSharedTrialTypeList,
  useSharedList,
} from "../SharedHooks";
import { PatientIDContext } from "../../Contexts";
import { submitDimensions, checkDevice } from "../../util/recordingAPI";
import { Link, useParams, useHistory } from "react-router-dom";
import ScanningDevicesSpinner from "./ScanningDevicesSpinner";
import NoDevicesFound from "./NoDevicesFound";
import CalibrationTable from "./CalibrationTable";
import CalibrationViewModal from "./CalibrationViewModal";
import { notificationActions } from "../../store/notification-slice";
import { useDispatch } from "react-redux";


function RecordCalibrationV2(props) {
  const dispatch = useDispatch();
  const history = useHistory()
  const origin = history.location.state
  const { patientID } = useParams();
  const [assessmentList, setAssessmentList] = useState([]);
  const { trialData, setTrialData } = useSharedTrialData();
  const [device, setDevice] = useState({
    finishedChecking: false,
    devicesFound: false,
  })
  const [calibrations, setCalibrations] = useState([]);
  const currentTime = new Date().toString().split(" ").slice(1, 5).join(" ");
  const title = `calibration ${currentTime}`;
  const key = title;
  const instructions =
    "Please record a new calibration video, make sure the checkboard is visible by all the cameras";
  const [calibrationView, setCalibrationView] = useState(null);
  const selectCalibrationView = (id) => {
    getCalibrationVideoUrl(id)
      .then((res) =>
        setCalibrationView({ videos: res.result, calibration_id: id })
      )
      .catch((e) => {
        dispatch(
          notificationActions.setNotification({
            show: true,
            status: "error",
            message: e.response.data.reason,
          })
        );
      });
  };
  useEffect(() => {
    handleRecordCalibration()
  }, []);
  useEffect(() => {
    checkDevice()
      .then((res) => {
        if (res.status != "success") {
          // alert(
          //   "No recording device detected on local network. Please check it is powered on and on the same wifi."
          // );
          setDevice({ ...device, devicesFound: false })
          return
        }
        setDevice({ ...device, devicesFound: true })
      })
      .catch((err) => {
        // alert(
        //   "No recording device detected on local network. Please check it is powered on and on the same wifi."
        // );
        setDevice({ ...device, devicesFound: false })
      }).finally(() => setDevice({ ...device, finishedChecking: true }));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCalibrationVideos();
        if (response && response.result) {
          const dataArr = response.result.map(video => ({
            id: video.id,
            name: video.name.split(' ').slice(1).join(' '),
            status: video.status,
            score: video.reprojection_error !== null && video.reprojection_error !== undefined ? parseFloat(video.reprojection_error.toFixed(2)) : null,
            video_count: video.video_count,
            err_msg: video.error_message
          }));
          setCalibrations(dataArr);
          updateCounts(dataArr); // Call to update counts asynchronously
        } else {
          console.log('Unexpected response structure:', response);
        }
      } catch (err) {
        console.error("Error fetching calibration videos", err);
      }
    }
  
    const updateCounts = async (dataArr) => {
      const updatedDataArr = await Promise.all(dataArr.map(async (video) => {
        if (video.video_count === null) {
          const video_count = await getCountVideos(video.id);
          return { ...video, video_count: video_count };
        }
        return video;
      }));
      setCalibrations(updatedDataArr);
    }
  
    fetchData();
  }, []); // Removed calibrations from dependency array



  const handleRecordCalibration = (option) => {
      trialData[title] = { id: "", notes: "" };
      setTrialData(trialData);
      setAssessmentList(currentList => [
          ...currentList,
          <RecordAssessment key={"key"} title={title} instructions={instructions} />
      ]);
    };

  const pushUrl = `/setup/${patientID}`;
  return <PatientIDContext.Provider value={patientID}>
    <div className="parent-container">
      <div className="container">
        <h1 className="page-title">Record Calibration</h1>
      </div>
      <small style={{color: 'gray'}}>
        Calibration board specs: 7 rows, 5 columns, board size = 600mm x 800mm, checker size = 100mm, marker size 78mm
      </small>
      {/* <div className="checkerboard">
        <label>Checkerboard Width</label>
        <input defaultValue={localStorage.getItem('checkerboardWidth')} onChange={(e) => localStorage.setItem('checkerboardWidth', e.target.value)}></input>
      </div>
      <div className="checkerboard">
        <label>Checkerboard Length</label>
        <input defaultValue={localStorage.getItem('checkerboardLength')} onChange={(e) => localStorage.setItem('checkerboardLength', e.target.value)}></input>
      </div> */}
      <div className="container-calibration">
        {assessmentList}
        {true && (
          <table id="assessment-table">
            <tbody>
            </tbody>
          </table>
        )}
      </div>
      {/* modal component to view calibration */}
      {calibrationView?.videos && (
        <CalibrationViewModal
          handleClose={() => setCalibrationView(null)}
          data={calibrationView}
        />
      )}
      <CalibrationTable data={calibrations} setCalibrations={setCalibrations} selectCalibrationView={selectCalibrationView} />
    </div>
  </PatientIDContext.Provider>

    ;
}

export default RecordCalibrationV2;

