import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const InfoCard = (props) => {
    return (
        <Box display="flex" flexDirection="column" gap={1} borderRadius={3} padding={3} boxShadow={3} width="100%">
            <Typography variant="h6">
                Trial Info:
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
                <Typography sx={{ fontWeight: 'bold' }}>Patient Name: </Typography>
                {props.patientInfo.patient_name && (
                    <Typography >{props.patientInfo.patient_name}</Typography>
                )}
            </Box>
            <Box display="flex" gap={1} alignItems="center">
                <Typography sx={{ fontWeight: 'bold' }}>Trial ID: </Typography>
                {props.trialInfo.id && (
                    <Typography >{props.trialInfo.id}</Typography>
                )}
            </Box>
            <Box display="flex" gap={1} alignItems="center">
                <Typography sx={{ fontWeight: 'bold' }}>Type: </Typography>
                {props.trialInfo.type && (
                    <Typography>{props.trialInfo.type}</Typography>
                )}
            </Box>
            <Box display="flex" gap={1} alignItems="center">
                <Typography sx={{ fontWeight: 'bold' }}>Date: </Typography>
                {props.trialInfo.name && (
                    <Typography>{props.trialInfo.name}</Typography>
                )}
            </Box>
        </Box>
    );
}

export default InfoCard;