import React, { useEffect, useState } from "react";

import {
  Switch,
  Route,
  Router,
  HashRouter,
  BrowserRouter,
  Layout,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import PatientsPage from "./components/patients/PatientsPage";
import AddNewPatientPage from "./components/patients/AddNewPatientPage";
import PatientDashboard from "./components/patients/patientDashboard/PatientDashboard";
import RecordPatient from "./components/record/RecordPatient";
import ReviewPatient from "./components/review/ReviewPatient";
import ReportPatient from "./components/report/ReportPatient";
import { useisLoggedIn } from "./components/SharedHooks";
import PrivateRoute from "./Protected";
import LoginRoute from "./LoginRoute";
import HomeRoute from "./HomeRoute";
import SetupPage from "./components/setup/SetupPage";
import NotificationModal from "./components/modals/NotificationModal";
import { useSelector } from "react-redux";
import Navbar from "./components/Navbar";
import NewRecordingPage from "./components/record/NewRecordingPage.jsx";
import CompareTrialsPage from "./components/patients/CompareTrialsPage";
import NotificationPanel from "./components/NotificationPanel";
import PatientReport from "./components/report/PatientReportViewPage";
import PatientReportViewPage from "./components/report/PatientReportViewPage";
import RecordingsPage from "./components/record/RecordingsPage";
import RecordCalibration from "./components/record/RecordCalibration";
import AccountPage from "./components/account/AccountPage";
import RecordCalibrationV2 from "./components/record/RecordCalibrationV2";
import RecordPatientV2 from "./components/record/RecordPatientV2";
import RecordPage from "./components/record/RecordPage";

const history = createBrowserHistory();

window.SERVER_URL = "https://app.evovision.ai";
// window.RECORDING_URL = "http://127.0.0.1:8080";
// TODO: Change RECORDING_URL to http://app.evovision.ai for wifi passthrough
window.RECORDING_URL = 'https://evovision.local:8080'
window.UPDATE_URL = 'https://evovision.local:5001'


// ONLY FOR SRA: For wired connection, check the IP address in flask app running on a specific raspberry pi
// window.RECORDING_URL = "http://192.168.137.74:8080"

function App() {
  // const notification = useSelector((state) => state.notification);
  const [test, setTest] = useState(() => {
    console.log("test state");
    return 1;
  });
  const NavBarRoutes = () => {
    return (
      <>
        <Navbar />
        <Switch>
          <PrivateRoute exact path="/patients">
            <PatientsPage />
          </PrivateRoute>
          <PrivateRoute path="/new_patient">
            <AddNewPatientPage />
          </PrivateRoute>
          <PrivateRoute exact path="/patients/patient_dashboard/:patientID">
            <PatientDashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/recordings">
            <RecordingsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/record-test">
            <RecordPage />
          </PrivateRoute>
          <PrivateRoute exact path="/recordings/new-recording">
            <NewRecordingPage />
          </PrivateRoute>
          <PrivateRoute path="/recordings/record_patient/:patientID">
            <RecordPatientV2 />
          </PrivateRoute>
          <PrivateRoute path="/recordings/record-calibration">
            <RecordCalibrationV2 />
          </PrivateRoute>
          <PrivateRoute path="/calibration">
            <RecordCalibrationV2 />
          </PrivateRoute>
          <PrivateRoute path="/devices">
            <SetupPage />
          </PrivateRoute>
          <PrivateRoute path="/setup/:patientID">
            <SetupPage />
          </PrivateRoute>
          <PrivateRoute path="/patients/review_patient/:patientID/:trialID">
            <ReviewPatient />
          </PrivateRoute>
          <PrivateRoute path="/patients/report_patient/:patientID">
            <ReportPatient />
          </PrivateRoute>
          <PrivateRoute path="/patients/review_report/:patientID/:reportID">
            <PatientReportViewPage />
          </PrivateRoute>
          <PrivateRoute path="/patients/compare-trials/:patientID/">
            <CompareTrialsPage />
          </PrivateRoute>
          <PrivateRoute path="/account">
            <AccountPage />
          </PrivateRoute>
        </Switch>
      </>
    );
  };
  return (
    <HashRouter history={history}>
      <NotificationPanel/>
      <Switch>
        <LoginRoute path="/login">
          <LoginPage />
        </LoginRoute>
        <Route path="/signup" component={SignUpPage} />
        <Redirect exact from="/" to="/login" />
        <Route component={NavBarRoutes}></Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
