import React, { useState } from 'react';
import { useBetween } from 'use-between';
import {useLocation} from 'react-router-dom'
function useQuery(){
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
// Using the use-between library
// Documentation: https://github.com/betula/use-between

// Allows the use of hooks to be shared among components that are siblings or descendants of siblings
function allowRecordFunc() {
    const [allowRecord, setAllowRecord] = useState(true);
    return { allowRecord, setAllowRecord };
}
const useSharedAllowRecord = () => useBetween(allowRecordFunc);

function resolutionFunc() {
    const [resolution, setResolution] = useState("480p");
    return { resolution, setResolution };
}
const useSharedResolution = () => useBetween(resolutionFunc);

function replayAssessmentFunc() {
    const [replayAssessment, setReplayAssessment] = useState("");
    return { replayAssessment, setReplayAssessment };
}
const useSharedReplayAssessment = () => useBetween(replayAssessmentFunc);

function trialDataFunc() {
    const [trialData, setTrialData] = useState({});
    return { trialData, setTrialData };
}
const useSharedTrialData = () => useBetween(trialDataFunc);

function cameraStream() {
    const [cameraStream, setCameraStream] = useState(true);
    return { cameraStream, setCameraStream };
}
const useSharedCameraStream = () => useBetween(cameraStream);

function SaveButton() {
    const [SaveButton, setSaveButton] = useState(false);
    return {SaveButton, setSaveButton};
}
const useSaveButton = () => useBetween(SaveButton);



function isLoggedIn() {
    const [isLoggedIn, setisLoggedIn] = useState(false);
    return {isLoggedIn, setisLoggedIn};
}
const useisLoggedIn = () => useBetween(isLoggedIn);


function assessmentList() {
    const [assessmentList, setAssessmentList] = useState([]);
    return {assessmentList, setAssessmentList};
}

const useSharedList = () => useBetween(assessmentList);

function filename() {
    const [filename, setFilename] = useState("");
    return {filename, setFilename};
}
const useFilename = () => useBetween(filename);




function trialTypeList() {
    const [trialTypeList, setTrialTypeList] = useState([]);
    return {trialTypeList, setTrialTypeList};
}
const useSharedTrialTypeList = () => useBetween(trialTypeList);



function Listidx() {
    const [Listidx, setListidx] = useState([]);
    return {Listidx, setListidx};
}
const useSharedListidx = () => useBetween(Listidx);

function calibrationIDFunc() {
    const [calibrationID, setcalibrationID] = useState("");
    return { calibrationID, setcalibrationID };
}
const useSharedCalibrationID = () => useBetween(calibrationIDFunc);

function filterTrialsFunc() {
    const [selectedTrials, setSelectedTrials] = useState([]);
    return { selectedTrials, setSelectedTrials };
}
const useSharedFilterTrials = () => useBetween(filterTrialsFunc);

function metricsSelectedFunc() {
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    return { selectedMetrics, setSelectedMetrics };
}
const useSharedFilterMetrics = () => useBetween(metricsSelectedFunc);

export { 
    useSharedCalibrationID,
    useSharedListidx,
    useSharedTrialTypeList,
    useSharedList,
    useFilename,
    useSaveButton,
    useSharedAllowRecord, 
    useSharedCameraStream,
    useSharedFilterTrials,
    useSharedFilterMetrics,
    useSharedReplayAssessment,
    useSharedResolution, 
    useSharedTrialData,
    useisLoggedIn,
    useQuery
};