import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useFormik } from 'formik';
import { Link, Redirect, useHistory} from 'react-router-dom';
import '../../dist/assets/stylesheets/css/login.css';
import { LogIn } from '../util/loginAPI';
import { useisLoggedIn } from './SharedHooks';
import SignUpPage from './SignUpPage';
import logo from './logo.PNG';
import { Icon } from '@iconify/react';


function LoginPage() {
  // const {isLoggedIn, setisLoggedIn} = useisLoggedIn();
  const formik = useFormik({
      initialValues: {
          email: "Enter your email",
          password: "Enter your Password",
      }
  })

  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {values, setFieldValue } = formik;
  const history = useHistory()
  const [modifiedE, setModifiedE] = useState(false);
  const [modifiedP, setModifiedP] = useState(false);
  const inputStyle = { width: "100%", height: "40px", fontSize: "16px", marginBottom: "20px" }
  const placeholderStyle = {color: 'grey', opacity: '0.8'}



  let handleSubmit = async (e) => {
      e.preventDefault();
      try {
        let res = await LogIn({...values});
        console.log(res);
        window.res = res;
        if (res && (res['status'] == 'success')) {
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('email', values.email);
          localStorage.setItem('token', res.token);
          console.log("logged in! redirecting to patients..")
          history.push('/patients');
          setIncorrectPassword(false);
        }
        else {
          localStorage.setItem('isLoggedIn', false);
          setIncorrectPassword(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

  return (<div id="login-page">
    <section id="login-container">
      <div style={{ textAlign: "center" }}>
      <img src={logo} alt="Logo" style={{ width: '400px', height: '130px' , marginBottom: "40px"}} />
      </div>

      <form onSubmit={handleSubmit} id="login-form">

      <label style={{ fontSize:"16px", display: "block", fontWeight: "bold", marginBottom: "8px"}}> Email </label>
      <input 
          onChange={e => {setFieldValue("email", e.target.value); 
                          setModifiedE(true); 
                          }}
          className="login-input"
          value={values.email}
          onFocus={() => {if (!modifiedE)setFieldValue("email", "")}}
          style={Object.assign({}, inputStyle, modifiedE ? null : placeholderStyle)}
          onBlur={() => {
            if (values.email === "") {
              setFieldValue("email","Enter your Email");
              setModifiedE(false);
            }
          }}
        />
        
        <label style={{ fontSize:"16px", display: "block", fontWeight: "bold", marginBottom: "8px"}}> Password </label>
        
        <div style={{ display: "flex" }}>
          <input 
            onChange={e => {setFieldValue("password", e.target.value); 
            setModifiedP(true); }}
            className="login-input"
            type={showPassword ? "text" : "password"}
            style={Object.assign({}, inputStyle, modifiedP ? null : placeholderStyle)}
            value={values.password}
            onFocus={() => {if (!modifiedP)setFieldValue("password", "")}}
            onBlur={() => {
              if (values.password === "") {
                setFieldValue("password","Enter your Password");
                setModifiedP(false);
              }
            }}
          />
          <div style={{ width: "10px" }}></div>

          <button type="button" onClick={() => setShowPassword(!showPassword)} style={{ height: "40px", fontSize: "16px", marginBottom: "30px", width:"50px",fontWeight:"400" }}>
            {showPassword ? <Icon icon="clarity:eye-hide-line" /> : <Icon icon="mdi:show-outline" />
}
          </button>
        </div>

        {incorrectPassword && <div className = 'error-message'>Email and password do not match.</div>}
        <div className='button-wrapper'>
          <button className="blue-button" type="submit" style={{marginBottom: "20px" }}>
            Log In
          </button>
          <span style={{textAlign:'center'}}>Don't have an account?
          <Link to="/signup" style={{marginLeft:"5px",color:'#63C7CF'}}>
            Sign up            
          </Link>
          </span>
        </div>
      </form>
    </section>
    <section id="image-container">
      {/* <img src="https://images.squarespace-cdn.com/content/v1/5f6bccd37f6369040294ef40/3ccbb190-5dd3-4f03-a911-306cf7a5ec35/shutterstock_1476961856+copy+%281%29.jpg?format=1500w"></img> */}
    </section>
    </div>
  );
}

export default LoginPage;
