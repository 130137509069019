import React, { Component, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function TrialInfoContainer(props) {
  return (
    <Box borderRadius={3} padding={2} boxShadow={3} width="100%">
      <Typography variant="h6">
        Trial Info:
      </Typography>
      <Box display="flex" justifyContent="space-between">
        {props.trialInfo.id && (
          <Box>
            <Typography variant="h6">ID</Typography>
            <Typography >{props.trialInfo.id}</Typography>
          </Box>
        )}
        {props.trialInfo.type && (
          <Box>
            <Typography variant="h6">Type</Typography>
            <Typography>{props.trialInfo.type}</Typography>
          </Box>
        )}
      </Box>
      <Box>
        {props.trialInfo.name && (
          <Box>
            <Typography variant="h6">Date</Typography>
            <Typography>{props.trialInfo.name}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );


  // return (
  //   <>
  //   <div className="trial-info-big-container">
  //   <h3>Trial Information:</h3>
  //   <div className="trial-info-container">
  //   {props.trialInfo.id && (
  //       <div>
  //         <span className="title">ID</span><br/>
  //         <span className="info">{props.trialInfo.id}</span>
  //       </div>
  //     )}
  //   {props.trialInfo.name && (
  //       <div>
  //         <span className="title">Name</span><br/>
  //         <span className="info">{props.trialInfo.name}</span>
  //       </div>
  //     )}

  //     {props.trialInfo.type && (
  //       <div>
  //         <span className="title">Type</span><br/>
  //         <span className="info">{props.trialInfo.type}</span>
  //       </div>
  //     )}
  //     {props.trialInfo.notes && (
  //       <div>
  //         <span className="title">Notes</span><br/>
  //         <span className="info">{props.trialInfo.notes}</span>
  //       </div>
  //     )}

  //   </div>
  //   </div>
  //   </>
  // );
}

export default TrialInfoContainer;
