import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../../../../dist/assets/stylesheets/css/delete.css'
import '../../Confirm'
import Confirm from '../../Confirm';
import { deleteTrial } from '../../../util/trialApi';



function TrialRow({ trial }) {
  const { id, type, name,notes } = trial;
  const params = useParams();
  const patient_id = Object.values(params)[0];
  const pushUrl = `/patients/review_patient/${patient_id}/${id}`;
  const [confirmDeletion, setConfirmDeletion] = React.useState(false);
  
  const handleClick = (event) => {
    event.preventDefault();
    setConfirmDeletion(true);
  };

  const handleCancel = ()=>{
    setConfirmDeletion(false);
  }


  return (
    <>
      <tr className='patient-row'>
        <td>
          <Link to={pushUrl}>
            <div>{id}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
            <div>{type}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
              <div>{name}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
            <div>{notes}</div>
          </Link>
        </td>
      </tr>
    </>
  )
}

export default TrialRow;
