import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { PatientIDContext } from '../../Contexts';
import { getTrials } from '../../util/trialApi';
import { useParams } from 'react-router-dom';

function TrialDropDown(props) {
    const { handleChange, selectedOption } = props;
    const patientID = useContext(PatientIDContext);
    const [trials, setTrials] = useState([]);


    useEffect(() => {
        getTrials(patientID)
            .then(res => {
                const trials = (res.result || []).map(trial => ({value: trial.id, label: trial.name})); // value: ID and label: date
                setTrials(trials);
                // console.log(trials[0].value)
                // console.log(selectedOption)
                // const defaultOption = trials.filter(option => option.value.toString() === selectedOption)[0];
                // console.log(defaultOption)
            })
    }, [patientID])

    return (
        <section className="container">
            <Select
                placeholder={"Please select a trial to analyze"}
                //isClearable
                styles={{
                    control:(baseStyle,state)=>({
                        ...baseStyle,
                        border:'none',
                        outline:'none',
                        boxShadow:'none',
                        cursor:'pointer'
                    }),
                    dropdownIndicator:(baseStyle,state)=>({
                        ...baseStyle,
                        color:'#63C7CF',
                        cursor:'pointer'
                    }),
                    indicatorSeparator:(baseStyle,state)=>({
                        display:'none',
                    }),
                    singleValue:(baseStyle,state)=>({
                        fontSize:'1.2rem'
                    })
                }}
                className="trial select-dropdown"
                options={trials}
                onChange={option => option ? handleChange(option.value) : handleChange("")}
                value={trials.filter(option => option.value.toString() === selectedOption)[0]}
            />
        </section>
    )
}

export default TrialDropDown;
