import axios from "axios";
export function getUserData() {
  const token = localStorage.getItem("token");
  return fetch(window.SERVER_URL + `/a/get-user`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
}
export function changePassword(data) {
  const token = localStorage.getItem("token");
  return axios
    .post(
      window.SERVER_URL + `/a/change-password`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data)
}
export function updateUser(data) {
  const token = localStorage.getItem("token");
  return axios
    .put(
      window.SERVER_URL + `/a/update-user`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then((res) => res.data).catch(e=>console.log(e))
}
