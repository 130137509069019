import {createSlice} from '@reduxjs/toolkit'
const videoCompare2Slice=createSlice({
    name:'videoCompare2',
    initialState:{
        currentTime:null,
        videoLength:null,
        isLoaded:false
    },
    reducers:{
        setLoaded:(state,action)=>{
            state.isLoaded=action.payload
        },
        updateCurrentTime:(state,action)=>{
            state.currentTime=action.payload
        }
    }
})
export const {updateCurrentTime} = videoCompare2Slice.actions
export default videoCompare2Slice
