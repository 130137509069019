import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Component} from "react-router-dom";
// import { useisLoggedIn } from './components/SharedHooks';
import LoginPage from './components/LoginPage';

const PrivateRoute = ({children, ...rest}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFetchDone, setIsFetchDone] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsFetchDone(true);
      setIsLoggedIn(false);
      return;
    }
    const verifyToken = async (token) => {
      try {
        let data = await fetch(window.SERVER_URL + "/a/verify", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        data = await data.json();
        setIsLoggedIn(true);
        setIsFetchDone(true);
        
      } catch (e) {
        setIsLoggedIn(false);
        setIsFetchDone(true);
      }
    };
    verifyToken(token);
  }, []);
    return(
        <Route {...rest}>
        {isFetchDone?(isLoggedIn?children:<Redirect to='/login'/>):''}
        </Route>
    )
}


export default PrivateRoute;

