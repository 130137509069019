import React, { useEffect,useState } from 'react';
import ReportFilters from './reportFilters/ReportFilters';
import Notes from '../notes/Notes';
import BackButton from '../BackButton';
import { PatientIDContext } from '../../Contexts';
import ReportAssessmentSummaryContainer from './reportAssessmentSummary/ReportAssessmentSummaryContainer';
import MetricsOverTime from './metricsOverTime/MetricsOverTime';
import '../../../dist/assets/stylesheets/css/report.css'
import {useParams,useHistory} from 'react-router-dom'
import Select from 'react-select'
import { createReport, getTrials } from '../../util/reportAPI';
import { getMetrics } from '../../util/metricsAPI';
import VideoChartContainer from './VideoChartContainer';
import { useDispatch } from 'react-redux';
import { notificationActions } from '../../store/notification-slice';
function ReportPatient(props) {
    const { patientID } = useParams();
    const history=useHistory()
    const [report,setReport]=useState({
        title:'',
        selectedTrials:[],
        selectedAngles:[],
        notes:'',
    })
    const [allTrials,setAllTrials]=useState([])
    const dispatch=useDispatch()
    const [allAngles,setAllAngles]=useState([])
    useEffect(()=>{
        getTrials(patientID).then(res=>setAllTrials(res.result))
        getMetrics().then(res=>setAllAngles(res.angles))
    },[])
    const handleSubmit=()=>{
        let submitData={
            title:report.title,
            trials:report.selectedTrials,
            angles:report.selectedAngles,
            notes:report.notes,
            patient_id:patientID
        }
        createReport(submitData).then(res=>console.log(res))
        dispatch(notificationActions.setNotification({show:true,message:'Created report successfully!',status:'success'}))
        history.push('/patients/patient_dashboard/'+patientID+'?activeTab=reports')
    }
    return (
        <PatientIDContext.Provider value={patientID}>
            <div className="parent-container report-page">
                <BackButton url={'/patients/patient_dashboard/'+patientID} name='Dashboard'/>
                <div className='flex-container'>
                    <span className='label'>Select Trials</span>
                    <div style={{width:'100%'}}>
                    <Select styles={{
                        control:(baseStyle)=>({
                            ...baseStyle,
                            width:'100%'
                        })
                    }} onChange={(e)=>setReport({...report,selectedTrials:e?e.map(el=>el.value):[]})} options={allTrials.map(el=>({label:el.id+' - '+el.name,value:el.id}))} isMulti/>
                    </div>
                </div>
                <div className='flex-container'>
                    <span className='label'>Select Angles</span>
                    <div style={{width:'100%'}}>
                    <Select onChange={e=>setReport({...report,selectedAngles:e?e.map(el=>el.value):[]})} options={allAngles.map(el=>({label:el,value:el}))} isMulti/>
                    </div>
                </div>
                <div className='flex-container'>
                    <span className='label'>Title</span>
                    <input onChange={e=>setReport({...report,title:e.target.value})}/>
                </div>
                <div className='flex-container'>
                    <span className='label'>Notes</span>
                    <textarea onChange={e=>setReport({...report,notes:e.target.value})}/>
                </div>
                {report.selectedAngles.length>0&&report.selectedTrials.map(el=><VideoChartContainer angles={report.selectedAngles} trial_id={el}/>)}
                {report.title&&report.selectedTrials.length>0&&<button onClick={handleSubmit}>Create Report</button>}
                {/* <ReportFilters />
                <Notes />
                <ReportAssessmentSummaryContainer/>
                <MetricsOverTime /> */}
            </div>
        </PatientIDContext.Provider>
    )
}

export default ReportPatient;