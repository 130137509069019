import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import '../../../../dist/assets/stylesheets/css/delete.css'
import '../../Confirm'
import Confirm from '../../Confirm';
import { deleteTrial } from '../../../util/trialApi';
import moment from 'moment'


function ReportRow({ report }) {
  const { id, title,angles,trials, date } = report;
  const params = useParams();
  const patient_id = Object.values(params)[0];
  const pushUrl = `/patients/review_report/${patient_id}/${id   }`;
  const [confirmDeletion, setConfirmDeletion] = React.useState(false);
  
  const handleClick = (event) => {
    event.preventDefault();
    setConfirmDeletion(true);
  };

  const handleCancel = ()=>{
    setConfirmDeletion(false);
  }


  return (
    <>
      <tr className='patient-row'>
        <td>
          <Link to={pushUrl}>
            <div>{id}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
            <div>{title}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
              <div>{trials.join(', ')}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
            <div>{angles.join(', ')}</div>
          </Link>
        </td>
        <td>
          <Link to={pushUrl}>
            <div>{moment.utc(date).local().format('MM/DD/YYYY')}</div>
          </Link>
        </td>
      </tr>
    </>
  )
}

export default ReportRow;
