import React, { useState, useEffect } from 'react';
import { getMetricsSummary } from '../../../util/metricsAPI';
import { useSharedFilterMetrics, useSharedFilterTrials } from '../../SharedHooks';
import MetricsOverTimeGraph from './MetricsOverTimeGraph';

function MetricsOverTime() {

    const [graphs, setGraphs] = useState([]); 
    const { selectedTrials } = useSharedFilterTrials();
    const { selectedMetrics } = useSharedFilterMetrics();

    useEffect(() => {
        getMetricsSummary(selectedTrials, selectedMetrics).then((res) => {
          if (Array.isArray(res)) {
            const graphs = res.map((data) => (
              <MetricsOverTimeGraph key={data.metric_name} graphInfo={data} />
            ));
            setGraphs(graphs);
          }
        });
      }, [selectedTrials, selectedMetrics]);

    return (
        <section className="container">
            <p>Metrics Over Time</p>
            <div className="graph-over-time-container">
                {graphs}
            </div>
        </section>
    )
}   

export default MetricsOverTime;