import * as React from 'react';
import { styled } from '@mui/material/styles';
import "../../../dist/assets/stylesheets/css/recordPatient.css"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Select from 'react-select'
import moment from 'moment';
import { deleteTrial, processTrial } from '../../util/trialApi';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../../../dist/assets/stylesheets/css/recordPatient.css';

const CommonButton = styled(Button)({
    color: 'black',
    '& svg': {
        marginRight: '5px',
        fontSize: '1.2rem',
    }
});

const CircleButton = styled(Button)({
    color: 'black',
    borderRadius: '50%',
    padding: '5px',
    minWidth: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    fontSize: '1.2rem',
});

const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'complete', label: 'Complete' },
    { value: 'processing', label: 'Processing' },
    { value: 'ready', label: 'Ready' },
    { value: 'failed', label: 'Failed' },
    { value: 'uploading', label: 'Uploading' },
];

const RecordingsTableV2 = (props) => {
    const [trials, setTrials] = useState(props.trials || []);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredTrials, setFilteredTrials] = useState(props.trials || []);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const history = useHistory();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);

    const handleClickOpen = (id) => {
        setCurrentId(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const filterTrials = () => {
        let tempTrials = trials.filter(trial => {
            const patientName = trial.patient_name ? trial.patient_name.toLowerCase() : '';
            const trialId = trial.id ? trial.id.toString() : '';
            const trialDate = trial.date ? moment.utc(trial.date).local().format('MM/DD/YYYY HH:MM') : '';
            const trialStatus = trial.status ? trial.status.toLowerCase() : '';

            return (
                patientName.includes(searchText.toLowerCase()) ||
                trialId.includes(searchText) ||
                trialDate.includes(searchText) ||
                trialStatus.includes(searchText.toLowerCase())
            ) && (
                    selectedStatus === 'all' || trial.status === selectedStatus
                );
        });
        setFilteredTrials(tempTrials);
    };

    useEffect(() => {
        filterTrials();
    }, [searchText, selectedStatus, trials]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption.value);
    };

    useEffect(() => {
        setIsRefreshing(false);
        setTrials(props.trials || []);
    }, [props.trials]);

    const refresh = () => {
        setIsRefreshing(true);
        setTimeout(() => {
            props.refreshHandler();
            setIsRefreshing(false);
        }, 2000);
    };

    const handleRowClick = (params) => {
        const pushUrl = `/patients/review_patient/${params.row.patient_id}/${params.row.id}`;
        history.push({
            pathname: pushUrl,
            state: { backUrl: '/recordings', backName: 'Recordings' }
        });
    };

    const handleProcessTrial = async (event, trialID) => {
        event.stopPropagation();
        // Find the index of the trial to update its status locally before the API call
        const index = trials.findIndex(trial => trial.id === trialID);
        if (index !== -1) {
            let copy = [...trials];
            copy[index].status = 'processing';
            setTrials(copy); // Update UI optimistically

            try {
                // Await the API call for processing the trial
                await processTrial(trialID);
                // After successful API call, you might want to refresh the list to reflect any changes
                refresh();
            } catch (error) {
                console.error("Failed to process trial", error);
            }
        }
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Trial ID',
            flex: 1,
        },
        {
            field: 'patient_name',
            headerName: 'Patient Name',
            flex: 1,
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 2,
            valueFormatter: (params) => moment.utc(params.value).local().format('MM/DD/YYYY HH:MM'),
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const status = params.value ? params.value : 'failed';
                let color;
                switch (status) {
                    case 'complete':
                        color = "#9FD06A";
                        break;
                    case 'processing':
                        color = "#FFD700";
                        break;
                    case 'ready':
                        color = "#BDBDBD";
                        break;
                    case 'failed':
                        color = "#DD6364";
                        break;
                    case 'uploading':
                        color = "#92CEFA";
                        break;
                    default:
                        color = "#BDBDBD";
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{
                            height: '10px',
                            width: '10px',
                            backgroundColor: color,
                            borderRadius: '50%',
                            display: 'inline-block'
                        }}></span>
                        <span>{status}</span>
                    </div>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            flex: 2,
            renderCell: (params) => {
                const trialID = params.id;
                const status = params.row.status;
                return (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display="flex" gap={2}>
                            {['complete', 'processing', 'ready', 'failed'].includes(status) && (
                                <CommonButton onClick={(event) => {
                                    event.stopPropagation();
                                    props.handleView(trialID);
                                }}><Icon icon="carbon:view" />View</CommonButton>
                            )}
                            {status === 'ready' && (
                                <CommonButton onClick={(event) => handleProcessTrial(event, trialID)}><Icon icon="ion:push-outline" />Process</CommonButton>
                            )}
                            {status === 'failed' && (
                                <CommonButton onClick={(event) => handleProcessTrial(event, trialID)}><Icon icon="gg:redo" />Retry</CommonButton>
                            )}
                        </Box>
                        <Box>
                        </Box>
                        <Box>
                            <CircleButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickOpen(trialID);
                                }}
                            >
                                <Icon icon="uiw:delete" />
                            </CircleButton>
                        </Box>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box
            sx={{
                "& .MuiDataGrid-root": {
                    border: "none",
                },
                "& .MuiDataGrid-cell": {
                    // borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#EFEFEF",
                    color: "#75A7AB",
                },
                "& .MuiDataGrid-toolbarContainer": {
                    "& .MuiButton-root": {
                        // backgroundColor: "#EFEFEF",
                        // margin: "0 8px",
                        color: "#75A7AB",
                    },
                    // quickfilter style
                    '& .MuiTextField-root': {
                        // backgroundColor: 'lightgray',
                        // color: 'black',
                        // '& .MuiInputBase-input': {
                        //     color: 'blue',
                        // },
                    },
                },
            }}
            mb={2}
        >
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Box display="flex" gap={3}>
                    <input
                        placeholder='Search...'
                        style={{
                            width: '180px',
                            border: '1px solid #ddd',
                            paddingLeft: '10px'
                        }}
                        value={searchText}
                        onChange={handleSearchChange}
                    >
                    </input>
                    <Select
                        options={statusOptions}
                        defaultValue={statusOptions[0]}
                        onChange={handleStatusChange}
                        styles={{
                            control: (base) => ({
                                ...base,
                                width: '180px',
                                border: '1px solid #ddd'
                            })
                        }}
                    />
                </Box>
                <Box display="flex" gap={3}>
                    <CommonButton>
                        <Icon icon="tabler:refresh" />
                        Refresh
                    </CommonButton>
                    <Link to="/record-test">
                        <CommonButton>
                            <Icon icon="fluent:add-16-filled" />
                            Create New Recording
                        </CommonButton>
                    </Link>
                </Box>
            </Box>
            <DataGrid
                rows={filteredTrials}
                columns={columns}
                density="comfortable"
                getRowId={(row) => row.id}
                onRowClick={(params) => handleRowClick(params)}
                sx={{
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    boxShadow: 2,
                    fontSize: '1rem',
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                pageSizeOptions={[50, 75, 100]}
                disableSelectionOnClick
                // slots={{
                //     toolbar: GridToolbar,
                // }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                    panel: {
                        sx: {
                            "& .MuiTypography-root": {
                                // color: 'dodgerblue',
                                // fontSize: 20,
                            },
                            "& .MuiDataGrid-filterForm": {
                                // bgcolor: 'lightblue',
                            },
                        },
                    },
                }}
            />
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this Recording?
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Calibration ID: {currentId}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={() => {
                        deleteTrial(currentId);
                        handleClose();
                    }}>
                        Delete
                    </CommonButton>
                    <CommonButton onClick={handleClose}>Cancel</CommonButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RecordingsTableV2;