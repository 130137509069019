import React, { Component, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "../../../dist/assets/stylesheets/css/recordPatient.css";
import AnimateHeight from "react-animate-height";
import { patientsActions, fetchPatients } from "../../store/patients-slice";

import {
  checkDevice,
  getCalibrationVideos,
  getCalibrationVideoUrl,
  startRecording,
  stopRecording,
} from "../../util/recordingAPI";
import CalibrationSelection from "./CalibrationSelection";
import { getPatients } from "../../util/patientsApi";
import PatientSelection from "./PatientSelection";
import CalibrationViewModal from "./CalibrationViewModal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { notificationActions } from "../../store/notification-slice";
import { IoIosArrowBack } from "react-icons/io";
import { useQuery, useSharedResolution } from "../SharedHooks";
import { useHistory } from "react-router-dom";
import RecordingPanel from "./RecordingPanel";
import NoDevicesFound from "./NoDevicesFound";
import ScanningDevicesSpinner from "./ScanningDevicesSpinner";
import AddNewPatientModal from "../patients/AddNewPatientModal";
function NewRecordingPage() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const resolution=useSharedResolution()
  const [trials, setTrials] = useState([]);
  const [calibration, setCalibration] = useState({
    selected: localStorage.getItem("calibration") || null,
    open: false,
    data: null,
  });
  const patientsList = useSelector((state) => state.patients.patientsList);
  const [patient, setPatient] = useState({
    selected: query.get("selectedPatient") || false,
    open: false,
    data:patientsList||null
  });
  const [showNewPatientModal,setShowNewPatientModal]=useState(null)
  const [device,setDevice]=useState({
    finishedChecking:false,
    devicesFound:false,
  })
  const [calibrationView, setCalibrationView] = useState(null);
  useEffect(() => {
    checkDevice()
      .then((res) => {
        if (res.status != "success") {
          // alert(
          //   "No recording device detected on local network. Please check it is powered on and on the same wifi."
          // );
          setDevice({...device,devicesFound:false})
          return
        }
        setDevice({...device,devicesFound:true})
      })
      .catch((err) => {
        // alert(
        //   "No recording device detected on local network. Please check it is powered on and on the same wifi."
        // );
        setDevice({...device,devicesFound:false})
      }).finally(()=>setDevice({...device,finishedChecking:true}));
  }, []);
  useEffect(() => {
    getCalibrationVideos().then((res) =>
      setCalibration({ ...calibration, data: res.result })
    );
    // getPatients().then((res) => setPatient({ ...patient, data: res }));
    dispatch(fetchPatients());
  }, []);
  useEffect(()=>{
    setPatient({...patient,data:patientsList})
  },[patientsList])
  const selectCalibration = (selectedCalibration) => {
    localStorage.setItem("calibration", selectedCalibration);
    setCalibration({ ...calibration, selected: selectedCalibration });
  };
  const selectPatient = (id) => {
    history.push("?selectedPatient=" + id);
    setPatient({ ...patient, selected: id });
  };
  const selectCalibrationView = (id) => {
    getCalibrationVideoUrl(id)
      .then((res) =>
        setCalibrationView({ videos: res.result, calibration_id: id })
      )
      .catch((e) => {
        dispatch(
          notificationActions.setNotification({
            show: true,
            status: "error",
            message: e.response.data.reason,
          })
        );
      });
  };
  const processAllRecordings=()=>{
    document.querySelector('.process-recordings-btn').classList.add('clicked');
    fetch(window.RECORDING_URL+'/api/finish-session').then(res=>res.json()).then(res=>console.log(res))
  }
  const removeTrial=(index)=>{
    let copy=JSON.parse(JSON.stringify(trials))
    copy.splice(index,1)
    setTrials(copy)
  }
  const addNewTrial = () => {
    let new_trial = { 
      assessment: "trial",
      calibrationID:calibration.selected,
      patientID:patient.selected,
      finishedRecording:false,
      isRecording:false,
      res:resolution
    };

    setTrials([...trials, new_trial]);
  };
  const addNewPatient=()=>{
    setShowNewPatientModal(true)
  }
  const startRecord = (index) => {
    let copy=JSON.parse(JSON.stringify(trials))
    copy[index].isRecording=true
    console.log('copy is ',copy)
    /// capture trial_id here and display it
    startRecording(copy[index].patientID,copy[index].res,copy[index].assessment,copy[index].calibrationID)
    setTrials(copy)
  };
  const stopRecord=(index)=>{
    let copy=JSON.parse(JSON.stringify(trials))
    copy[index].isRecording=false
    copy[index].finishedRecording=true
    stopRecording()
    setTrials(copy)
  }
  const setFinishRecording=(index)=>{
    console.log('done',index)
    let trials_copy=JSON.parse(JSON.stringify(trials))
    trials_copy[index]={...trials_copy,finishedRecording:true}
    setTrials(trials_copy)
  }

  // if (!device.finishedChecking) {
  //   return (<ScanningDevicesSpinner/>);
  // } else if (!device.devicesFound) {
  //   return (<NoDevicesFound/>);
  // }

  return (
    <div className="recordings-page parent-container">
      <nav className="container">
        <Link to="/recordings">
          <button className=" back-btn">
            <IoIosArrowBack />
            Recordings
          </button>
        </Link>
      </nav>
      <h1 className="page-title">New Recording</h1>
      <span className="page-sub-title">
        Select calibration and patient to record a new trial.
      </span>
      {calibrationView?.videos && (
        <CalibrationViewModal
          handleClose={() => setCalibrationView(null)}
          data={calibrationView}
        />
      )}
      {showNewPatientModal&&<AddNewPatientModal handleClose={()=>setShowNewPatientModal(false)}/>}
      <AnimateHeight duration={500} height={"auto"}>
        <div className={"step-card-container"}>
          <div
            className="step-card"
            onClick={() =>
              setCalibration((prev) => ({ ...prev, open: !prev.open }))
            }
          >
            <div className="step-card-title-container">
              {calibration.selected ? (
                <Icon className="check" icon="ei:check" />
              ) : (
                <Icon icon="la:circle-solid" color="gray" />
              )}
              <h5>Select Calibration</h5>
              <span>
              {calibration.selected && calibration.data?.find((el) => el.id === calibration.selected)?.name}
              </span>
            </div>
            <Icon
              icon="iconamoon:arrow-up-2-light"
              className={calibration?.open && "open"}
            />
          </div>
          {calibration?.open && calibration.data && (
            <CalibrationSelection
              selectCalibration={selectCalibration}
              data={calibration.data}
              selectCalibrationView={selectCalibrationView}
              selected={calibration.selected}
            />
          )}
        </div>
      </AnimateHeight>
      <div className="step-card-container">
        <div
          className="step-card"
          onClick={() => setPatient((prev) => ({ ...prev, open: !prev.open }))}
        >
          <div className="step-card-title-container">
            {patient.selected ? (
              <Icon className="check" icon="ei:check" />
            ) : (
              <Icon icon="la:circle-solid" color="gray" />
            )}

            <h5>Select Patient</h5>
            <span>
              {patient.selected &&
                patient.data &&
                patient.data.find((el) => el.patient_id == patient.selected)
                  .patient_name}
            </span>
          </div>
          <Icon
            icon="iconamoon:arrow-up-2-light"
            className={patient?.open && "open"}
          />
        </div>
        {patient?.open && patient.data && (
          <PatientSelection
            selected={patient.selected}
            selectPatient={selectPatient}
            data={patient.data}
            addNewPatient={addNewPatient}
          />
        )}
      </div>
      {calibration.selected && patient.selected && (
        <button
          onClick={addNewTrial}
          className="new-trial-btn svg-btn"
          style={{ float: "right" }}
        >
          <Icon icon="mingcute:add-fill" />
          New Trial
        </button>
      )}
      {trials.map((el,index) => (
        <RecordingPanel
          trial={el}
          disabled={trials.some(el=>el.isRecording)&&!el.isRecording}
          startRecord={()=>startRecord(index)}
          stopRecord={()=>stopRecord(index)}
          removeTrial={()=>removeTrial(index)}
        />
      ))}
      {trials.length>0 && trials.every(trial=>trial.finishedRecording) && (
          <button className="process-recordings-btn" onClick={processAllRecordings}>Process Recordings</button>
      )}
    </div>);
}

export default NewRecordingPage;
