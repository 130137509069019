import React from 'react';

function Notes(props) {

    return (
        <section className="container">
            <div className="gray-background">
                <span>Notes:</span>
                <textarea/>
            </div>
        </section>
    )
}

export default Notes;