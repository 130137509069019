import React, { useState, useEffect, useContext } from "react";
import { PatientIDContext } from "../../../Contexts";
import { getTrials } from "../../../util/reportAPI";
import TrialRow from "./TrialRow";

function PatientTrialsTable(props) {
  // const [patientTrials, setPatientTrials] = useState(props.patientTrials);
  const patientID = useContext(PatientIDContext);
  const [query, setQuery] = useState("");
  const search_keys=['id','type','name','notes']
  const search=(data)=>{
    let filtered=data.filter(obj=>search_keys.some(key=>String(obj[key]).toLowerCase().includes(query.toLowerCase())))
    return filtered
    }
  // useEffect(() => {
  //   getTrials(patientID).then((data) => {
  //     const res = data.result;
  //     const sortedTrials = res.sort((a, b) => {
  //       const parseA = a.name.trim().split("--");
  //       const dateA = new Date(
  //         parseA[0] + "T" + parseA[1].replace(/-/g, ":") + "Z"
  //       );

  //       const parseB = b.name.trim().split("--");
  //       const dateB = new Date(
  //         parseB[0] + "T" + parseB[1].replace(/-/g, ":") + "Z"
  //       );

  //       return dateB - dateA;
  //     });
  //     setPatientTrials(sortedTrials);
  //   });
  // }, [patientID]);

  return (
    <section className="patient-table container">
    {props.patientTrials && props.patientTrials.length>0?<table>
      <tbody>
        <tr>
          <td colSpan="4" style={{ height: "fit-content" }}>
            <input
              id="search-bar"
              placeholder="Search..."
              onChange={(e) => setQuery(e.target.value)}
            ></input>
          </td>
        </tr>

        <tr>
          <th>Trial ID</th>
          <th>Assessment Type</th>
          <th>Name</th>
          <th>Notes</th>
        </tr>
        {search(props.patientTrials).map((trial) => (
        <TrialRow key={trial.id} trial={trial} />
      ))}
      </tbody>
    </table>:<div style={{'text-align':'center','margin-top':'30px','color':'gray','font-size':'1.2rem'}}>No data available.</div>}
    </section>
  );
}

export default PatientTrialsTable;
