import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../store/notification-slice';
import '../../../dist/assets/stylesheets/css/notification.css'
function NotificationModal() {
    const dispatch=useDispatch()
    const notification=useSelector(state=>state.notification)
    const handleClick=()=>{
        dispatch(notificationActions.setNotification({message:null,open:false}))
    }
    return <div className='modal-container'>
        <div className='notification modal'>
        <span>{notification.message}</span>
        <button onClick={handleClick}>OK</button>
        </div>
    </div>;
}

export default NotificationModal;