import React, { Component, useEffect,useState } from 'react';
import { PatientIDContext } from '../../Contexts';
import {useParams} from'react-router-dom'
import BackButton from '../BackButton';
import Select from 'react-select'
import { getTrials } from '../../util/trialApi';
import Subcompare1 from '../review/Subcompare1';
import Subcompare2 from '../review/Subcompare2';

function CompareTrialsPage() {
    const { patientID } = useParams();
    const [allTrials,setAllTrials]=useState(null)
    const [selected,setSelected]=useState({
        trial_id_1:null,
        trial_id_2:null
    })
    useEffect(()=>{
        getTrials(patientID).then(res=>setAllTrials(res.result))
    },[])

    return <PatientIDContext.Provider value={patientID}>
     <div className='parent-container compare-trials-page'>
        <BackButton url={'/patients/patient_dashboard/'+patientID+'?activeTab=reports'} name='Dashboard'/>
        <h1 className='page-title'>Compare Trials</h1>
        <div className='subcontainer-container'>
            <div className='subcontainer'>
            {allTrials&&<Select onChange={(e)=>setSelected({...selected,trial_id_1:e.value})} options={allTrials.map(el=>({label:el.id,value:el.id}))}></Select>}
            {allTrials&&selected?.trial_id_1&&<Subcompare1 trialId={selected.trial_id_1}/>}
            </div>
            <div className='subcontainer'>
            {allTrials&&<Select onChange={(e)=>setSelected({...selected,trial_id_2:e.value})} options={allTrials.map(el=>({label:el.id,value:el.id}))}></Select>}
            {allTrials&&selected?.trial_id_2&&<Subcompare2 trialId={selected.trial_id_2}/>}

            </div>
        </div>
    </div>;
    </PatientIDContext.Provider>

}

export default CompareTrialsPage;