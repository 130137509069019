import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getPatients } from "../util/patientsApi";
export const fetchPatients=createAsyncThunk("patients/fetchPatients",()=>{
    return getPatients()
})
const patientsSlice = createSlice({
  name: "patients",
  initialState: {
    patientsList: [],
    loading:false
  },
  reducers: {
    // fetchPatients: (state) => {
    //   getPatients().then((res) => {
    //     const sortPatients = res.sort((a, b) => a.patient_id < b.patient_id);
    //     state.patientsList = sortPatients;
    //   });
    // },
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchPatients.pending,(state,action)=>{
            state.loading=true
        }),
        builder.addCase(fetchPatients.fulfilled,(state,action)=>{
            state.loading=false
            state.patientsList=action.payload
        }),
        builder.addCase(fetchPatients.rejected,(state,action)=>{
            state.loading=false
        })
    }
  ,
});
export const patientsActions = patientsSlice.actions;
export default patientsSlice;
