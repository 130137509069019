import {createSlice} from '@reduxjs/toolkit'
const notificationSlice=createSlice({
    name:'notification',
    initialState:{
        show:false,
        message:'',
        status:null,
    },
    reducers:{
        setNotification:(state,action)=>{
            state.show=action.payload.show
            state.message=action.payload.message
            state.status=action.payload.status
        },
        closeNotification:(state)=>{
            state.show=false,
            state.message='',
            state.status=null
        }
    }
})
export const notificationActions = notificationSlice.actions
export default notificationSlice