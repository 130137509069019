import React, { useState, useEffect } from 'react';
import { saveNotes, checkDevice } from '../../../util/recordingAPI';
import { useSharedAllowRecord, useSharedCameraStream, useSharedTrialData, useSharedCalibrationID, useSharedListidx, useSharedTrialTypeList, useSharedList, useSaveButton, useFilename} from '../../SharedHooks';
import RecordAssessment from './RecordAssessment';
import '../../../../dist/assets/stylesheets/css/recordPatient.css';


function RecordAssessmentContainer(props) {
    const { trialType } = props;
    const {assessmentList, setAssessmentList} = useSharedList();
    const [display, setDisplay] = useState(true);
    const {SaveButton, setSaveButton} = useSaveButton();
    const { trialData, setTrialData } = useSharedTrialData();
    const { trialTypeList, setTrialTypeList} = useSharedTrialTypeList();
    const { allowRecord } = useSharedAllowRecord();
    const { cameraStream, setCameraStream } = useSharedCameraStream();
    const {filename, setFilename}= useFilename();
    const {Listidx, setListidx} = useSharedListidx();
    const { calibrationID, setcalibrationID } = useSharedCalibrationID();

    const toggleDisplay = () => setDisplay(!display);

    checkDevice().then(res => {
        console.log("recording", res);
        if(res.status != "success") {
            alert("No recording device detected on local network. Please check it is powered on and on the same wifi.");
        }
    });


    const saveAssessments = () => {
        if(assessmentList.length) {
            assessmentList.forEach(data => {
                const { title } = data.props;
                const { id, notes } = trialData[title];
                // API calls will collide without a timer
                // must be called and executed at different times
                if(id) setTimeout(() => {
                    setSaveButton(true)
                    saveNotes(id, notes);
                    console.log("Notes are saved");
                }, Math.random() * 2000);
            })
        }
    }

    const addAssessment = async (e)  => {
        e.preventDefault();   
        setTrialTypeList(trialTypeList.concat(trialType));
        const counts = {};
        for (const num of trialTypeList) {
        counts[num] = counts[num] ? counts[num] + 1 : 1;
        };
        var title = trialType + " " + "1";
        if (counts[trialType]>0){
        title = trialType + " " + (counts[trialType]+1);
        }
        let instructions = "Make sure to be visible by all the cameras.";
        trialData[title] = {id: "", notes: ""}
        setTrialData(trialData)
        const key=title
        setAssessmentList(assessmentList.concat(<RecordAssessment key={key} title={title} instructions={instructions}/>));
   }
    return (
        <section className="container">
            <nav>
                <div className="toggable" onClick={toggleDisplay}>
                    {display ? <i className="fas fa-caret-down"/> : <i className="fas fa-caret-right"/>}
                    <span>Assessments</span>
                </div>
                
                <div className="button-container">
                    <button
                        onClick={() => {if(allowRecord) setCameraStream(true)}}
                        className={cameraStream ?  "" : "blue-button"}
                    >
                        Stream
                    </button>
                    <button
                        onClick={() => {if(allowRecord) setCameraStream(false)}}
                        className={cameraStream ? "blue-button" : ""}
                    >
                        Playback
                    </button>
                    <button 
                        onClick={saveAssessments}
                        className={SaveButton ?  "green-button" : "blue-button"}
                    >
                        Save
                    </button>
                </div>
            </nav>
            <button className={"blue-button"}
                     onClick={trialType!=="" ? addAssessment : undefined}
            > Add Assessment 
            </button>
            <table id="assessment-table" style={{display: display ? "" : "none"}}>
                <tbody>
                    {assessmentList}
                    </tbody>
            </table>

        </section>
    )
}

export default RecordAssessmentContainer;
