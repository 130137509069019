export async function getMetrics() {
    const token = localStorage.getItem("token");
    return fetch(window.SERVER_URL + `/a/get-dict-metrics`,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
      .then(res => res.json())
      .catch(err => console.log(err))
}

function handleResponse(response) {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(response);
    }
  }
  
export async function getValuesMetrics(trialID, type, name) {
  if(type && name) {
    const token = localStorage.getItem("token");
    return fetch(window.SERVER_URL + `/a/get-values-metrics?trial_id=${trialID}&type=${type}&name=${name}`,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
           .then(handleResponse)
           .catch((err) => console.log(err))
  }
}

export async function getMetricsSummary(trialIDList, metricNameList) {
    const trialParams = trialIDList.map(trial_id => `trial_id=${trial_id}`);
    const metricNameParams = metricNameList.map(name => `name=${name}`);
    const url = "/api/get-metrics-summary?"
                .concat(trialParams.join("&"))
                .concat("&")
                .concat(metricNameParams.join("&"));
    const token = localStorage.getItem("token");
    return fetch(window.SERVER_URL + url,{
      headers:{
        Authorization:'Bearer '+token
      }
    })
            .then(res => res.json())
            .catch(err => console.log(err))
}
