import React, { Component } from 'react';
import '../../../dist/assets/stylesheets/css/recordPatient.css'
import { Icon } from '@iconify/react';
import GreyButton from '../GreyButton';

function PatientSelection(props) {
    return <div className='table-container patient-selection'>
        <table>
            <tr>
                <th>Patient</th>
                <th>Email</th>
                <th>Condition</th>
                {/* <th>Notes</th> */}
            </tr>
            <tr>
                <th colSpan="3">
                    <GreyButton title="Add new patient" width="100%" onClick={props.addNewPatient} />
                </th>
            </tr>
            <tbody>
                {props.data.map((el, i) => <tr className={props.selected == el.patient_id ? 'selected' : ''} onClick={() => props.selectPatient(el.patient_id)}>
                    <td>{el.patient_name}</td>
                    <td>{el.patient_email}</td>
                    <td>{el.patient_condition}</td>
                    {/* <td>{el.patient_notes}</td> */}
                </tr>)}
            </tbody>
        </table>
        {/* <button className='add-patient-btn svg-btn' onClick={props.addNewPatient}><Icon icon="mingcute:add-fill" />Add New Patient</button> */}
    </div>;
}

export default PatientSelection;