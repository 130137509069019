import { createSlice } from "@reduxjs/toolkit";
const chartSlice = createSlice({
  name: "chart",
  initialState: {
    list_metrics: [
      "hip_right_flex", "hip_left_flex","shoulder_right_flex", "shoulder_left_flex",
    ],
  },
  reducers: {
    updateListMetrics: (state, action) => {
      state.list_metrics = [...new Set(action.payload)];
    },
  },
});
export const chartActions = chartSlice.actions;
export default chartSlice;
