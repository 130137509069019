import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PatientIDContext } from '../Contexts';
import { IoIosArrowBack } from 'react-icons/io';

function BackButton(props) {
    return (
        <nav className="container">
            <Link to={props.url}>
                <button className="back-btn">
                    <IoIosArrowBack />
                    Back to {props.name}
                </button>
            </Link>
        </nav>
    )
}

export default BackButton;