import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import '../../../dist/assets/stylesheets/css/newpatient.css';
import { createNewPatient } from '../../util/patientsApi';

function AddNewPatientPage() {

    const formik = useFormik({
        initialValues: {
            name: "",
            condition: "",
            notes: "",
            email:"",
            height:"",
            weight:"",
            gender:"",
        }
    })

    const { values, setFieldValue } = formik;
    
    // CreatableSelect custom styles
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "#ececec",
            border: "none",
            borderRadius: "0"
        })
    }



    const handleSubmit = () => {
        createNewPatient({ ...values })
            .then(res => console.log);
    }
    return (
        <section id="new-patient-container">
            <header>New Patient Info</header>
            <form id="new-patient-form">
                
                    <label>Name: </label>
                    <input 
                        required
                        onChange={e => setFieldValue("name", e.target.value)}
                        className="new-patient-input"
                    />
                
                    <label>Condition: </label>
                    <input 
                        required
                        onChange={e => setFieldValue("condition", e.target.value)}
                        className="new-patient-input"
                    />

                    <label>Email: </label>
                    <input 
                        onChange={e => setFieldValue("email", e.target.value)}
                        className="new-patient-input"
                    />

                    <label>Height: </label>
                    <input 
                        onChange={e => setFieldValue("height", e.target.value)}
                        className="new-patient-input"
                    />

                    <label>Weight: </label>
                    <input 
                        onChange={e => setFieldValue("weight", e.target.value)}
                        className="new-patient-input"
                    />

                    <label>Gender: </label>
                    <input 
                        onChange={e => setFieldValue("gender", e.target.value)}
                        className="new-patient-input"
                    />

                
                    <label>Notes: </label>
                    <input 
                        onChange={e => setFieldValue("notes", e.target.value)}
                        className="new-patient-input"
                    />

                    
                
            </form>
            <div id="add-patient-button-container">
                <Link to="/patients">
                    <button type="button" onClick={handleSubmit}>Add Patient</button>
                </Link>
            </div>
        </section>
    )
}

export default AddNewPatientPage;   