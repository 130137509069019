import React, { Component, useEffect, useState } from "react";
import {
  deleteReport,
  getReport,
  getReports,
  getTrials,
  updateReport,
} from "../../util/reportAPI";
import { useParams, useHistory } from "react-router-dom";
import { Icon } from '@iconify/react';

import { PatientIDContext } from "../../Contexts";
import BackButton from "../BackButton";
import Select from "react-select";
import { getMetrics } from "../../util/metricsAPI";
import VideoChartContainer from "./VideoChartContainer";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../store/notification-slice";
import ConfirmModal from "../modals/ConfirmModal";

function PatientReportViewPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const reportID = params["reportID"];
  const patientID = params["patientID"];
  const [allTrials, setAllTrials] = useState([]);
  const [allAngles, setAllAngles] = useState([]);
  const [report, setReport] = useState(null);
  const [confirmModal,setShowConfirmModal]=useState({
    show:false,
    acceptHandler:null,
})
  const handleSubmit = () => {
    let submitData = {
      id: report.id,
      title: report.title,
      trials: report.trials,
      angles: report.angles,
      notes: report.notes,
      patient_id: patientID,
    };
    updateReport(submitData)
      .then((res) =>
        dispatch(
          notificationActions.setNotification({
            show: true,
            status: "success",
            message: "Updated report successfully!",
          })
        )
      )
      .catch((e) =>
        dispatch(
          notificationActions.setNotification({
            show: true,
            status: "error",
            message: e.response?.data.reason || "Something wrong happened",
          })
        )
      );
    // createReport(submitData).then(res=>console.log(res))
  };
  const handleDelete = () => {
    deleteReport(reportID).then((res) =>
      dispatch(
        notificationActions.setNotification({
          show: true,
          status: "success",
          message: "Deleted report successfully",
        })
      )
    );
    history.push(`/patients/patient_dashboard/${patientID}?activeTab=reports`);
  };
  const confirm=()=>{
    setShowConfirmModal({show:true,acceptHandler:handleDelete,message:'Are you sure you want to delete this report? This action cannot be undone.'})
}
  useEffect(() => {
    getReport(reportID).then((res) => setReport(res.data));
    getTrials(patientID).then((res) => setAllTrials(res.result));
    getMetrics().then((res) => setAllAngles(res.angles));
  }, []);
  return (
    <PatientIDContext.Provider value={patientID}>

      {report && (
        
        <div className="parent-container report-page">
            <BackButton url={'/patients/patient_dashboard/'+patientID+'?activeTab=reports'} name='Dashboard'/>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <h1 className="page-title">Report</h1>
            <button className="delete-button-trash" onClick={confirm}>
              <Icon icon="ant-design:delete-outlined" />
              Delete Report
            </button>
            </div>
          {confirmModal.show&&<ConfirmModal message={confirmModal.message} rejectHandler={()=>setShowConfirmModal({...confirmModal,show:false})} acceptHandler={confirmModal.acceptHandler} />}

          <div className="flex-container">
            <span className="label">Select Trials</span>
            <div style={{ width: "100%" }}>
              <Select
                styles={{
                  control: (baseStyle) => ({
                    ...baseStyle,
                    width: "100%",
                  }),
                }}
                value={allTrials
                  .filter((el) => report.trials.includes(el.id))
                  .map((el) => ({
                    label: el.id + " - " + el.name,
                    value: el.id,
                  }))}
                onChange={(e) =>
                  setReport({
                    ...report,
                    trials: e ? e.map((el) => el.value) : [],
                  })
                }
                options={allTrials.map((el) => ({
                  label: el.id + " - " + el.name,
                  value: el.id,
                }))}
                isMulti
              />
            </div>
          </div>
          <div className="flex-container">
            <span className="label">Select Angles</span>
            <div style={{ width: "100%" }}>
              <Select
                value={allAngles
                  .filter((el) => report.angles.includes(el))
                  .map((el) => ({ label: el, value: el }))}
                onChange={(e) =>
                  setReport({
                    ...report,
                    angles: e ? e.map((el) => el.value) : [],
                  })
                }
                options={allAngles.map((el) => ({ label: el, value: el }))}
                isMulti
              />
            </div>
          </div>
          <div className="flex-container">
            <span className="label">Title</span>
            <input
              defaultValue={report.title}
              onChange={(e) => setReport({ ...report, title: e.target.value })}
            />
          </div>
          <div className="flex-container">
            <span className="label">Notes</span>
            <textarea
              defaultValue={report.notes}
              onChange={(e) => setReport({ ...report, notes: e.target.value })}
            />
          </div>
          {report.angles.length > 0 &&
            report.trials.map((el) => (
              <VideoChartContainer angles={report.angles} trial_id={el} />
            ))}
          {report.title && report.trials.length > 0 && (
            <button className="svg-btn update-report-btn" onClick={handleSubmit}>Update Report</button>
          )}
          {/* <ReportFilters />
                <Notes />
                <ReportAssessmentSummaryContainer/>
                <MetricsOverTime /> */}
        </div>
      )}
    </PatientIDContext.Provider>
  );
}

export default PatientReportViewPage;
