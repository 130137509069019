export async function SignUp(data) {
    const response = await fetch(window.SERVER_URL + '/a/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    });
    // Directly return the response; let the caller handle the status.
    return response;
}

