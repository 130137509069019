import React, { useState, useEffect } from "react";
import ReviewChart from "./ReviewChart";
import "../../../../dist/assets/stylesheets/css/reviewPatient.css";
import { getMetrics, getValuesMetrics } from "../../../util/metricsAPI";
import DisplayCharts from "./DisplayCharts";
import {useSelector,useDispatch} from 'react-redux'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { chartActions } from "../../../store/chart-slice";

function ReviewChartsContainer(props) {
  const { trial, currentTime } = props;
  const list_metrics=useSelector(state=>state.chart.list_metrics)
  const dispatch=useDispatch()
  const [reviewCharts, setReviewCharts] = useState([]);
  const [reviewChartName, setReviewChartName] = useState("");
  // const [validMetrics, setValidMetrics] = useState([]);

  // const hardcodedMetrics = [
  //   "knee_right_flex", "knee_left_flex", "knee_right_rot", "knee_left_rot",
  //   "hip_right_flex", "hip_left_flex", "hip_right_rot", "hip_left_rot", "hip_right_abduct", "hip_left_abduct",
  //   "pelvic_tilt_left", "pelvic_tilt_right", "pelvic_rot_left", "pelvic_rot_right", "pelvic_obl_left", "pelvic_obl_right",
  //   "elbow_right_flex", "elbow_left_flex",
  //   "shoulder_right_flex", "shoulder_left_flex", "shoulder_right_rot", "shoulder_left_rot", "shoulder_right_abduct", "shoulder_left_abduct",
  //   "neck_flex", "neck_rot"
  // ];

  // useEffect(() => {
  //   dispatch(chartActions.updateListMetrics([

  //     "knee_right_flex",
  //     "knee_left_flex",
  //     "knee_right_rot",
  //     "knee_left_rot",

  //     "hip_right_flex",
  //     "hip_left_flex",
  //     "hip_right_rot",
  //     "hip_left_rot",
  //     "hip_right_abduct",
  //     "hip_left_abduct",

  //     "pelvic_tilt_left",
  //     "pelvic_tilt_right",
  //     "pelvic_rot_left",
  //     "pelvic_rot_right",
  //     "pelvic_obl_left",
  //     "pelvic_obl_right",


  //     "elbow_right_flex",
  //     "elbow_left_flex",
      
  //     "shoulder_right_flex",
  //     "shoulder_left_flex",
  //     "shoulder_right_rot",
  //     "shoulder_left_rot",
  //     "shoulder_right_abduct",
  //     "shoulder_left_abduct",

  //     "neck_flex",
  //     "neck_rot",
      
  //   ]))

  //   setReviewCharts([
  //     <ReviewChart
  //       handleMetricNameChange={setReviewChartName}
  //       trial={trial}
  //       key={new Date().getTime()}
  //     />,
  //   ]);
  // }, [trial]);

  useEffect(() => {
    const hardcodedMetrics = [
      "knee_right_flex", "knee_left_flex", "knee_right_rot", "knee_left_rot",
      "hip_right_flex", "hip_left_flex", "hip_right_rot", "hip_left_rot", "hip_right_abduct", "hip_left_abduct",
      "pelvic_tilt_left", "pelvic_tilt_right", "pelvic_rot_left", "pelvic_rot_right", "pelvic_obl_left", "pelvic_obl_right",
      "elbow_right_flex", "elbow_left_flex",
      "shoulder_right_flex", "shoulder_left_flex", "shoulder_right_rot", "shoulder_left_rot", "shoulder_right_abduct", "shoulder_left_abduct",
      "neck_flex", "neck_rot",
    ];
  
    // Initialize an empty array to hold valid metrics
    const validMetrics = [];
  
    // Using IIFE to handle async operations inside useEffect
    (async () => {
      for (let metric of hardcodedMetrics) {
        // Assuming metricType is defined or you need to define how to get it
        const metricType = "angles"; // Define how to determine the metricType for getValuesMetrics call
        await getValuesMetrics(trial, metricType, metric).then((res) => {
          console.log('fetch metrics', res);
          if (res && res.values && res.values.some(value => value[0] !== null)) {
            validMetrics.push(metric);
          }
        });
      }
  
      // Once all metrics are checked, dispatch the action with valid metrics
      dispatch(chartActions.updateListMetrics(validMetrics));
  
        // Set review charts for valid metrics
        setReviewCharts([
        <ReviewChart
          handleMetricNameChange={setReviewChartName}
          trial={trial}
          key={new Date().getTime()}
        />,
      ])

    })();
  }, [trial]); // Add other dependencies as needed


  // const addChart = () => {
  //   setListMetrics([...list_metrics, reviewChartName]);
  //   setReviewCharts([
  //     <ReviewChart
  //       handleMetricNameChange={setReviewChartName}
  //       trial={trial}
  //       key={new Date().getTime()}
  //     />,
  //   ]);
  // };

  const deleteChart = (index) => {
    let copy = [...list_metrics];
    copy.splice(index, 1);
    console.log("delete called", list_metrics, copy);
    dispatch(chartActions.updateListMetrics(copy))
  };
  const handleOnDragEnd=(result)=>{
    if (!result.destination) return;
    let list_metrics_copy=[...list_metrics]
    let [reorderedItem]=list_metrics_copy.splice(result.source.index,1)
    list_metrics_copy.splice(result.destination.index,0,reorderedItem)
    dispatch(chartActions.updateListMetrics(list_metrics_copy))

  }
  return trial !== "0" ? (
    <section className="container">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => {
            return <ul
              className="review-charts-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list_metrics.map((metric, index) => {
                return <Draggable key={metric} index={index} draggableId={metric}>
                    {(provided) => (
                      <li key={metric} {...provided.draggableProps} ref={provided.innerRef} >
                        <DisplayCharts
                          inComparePage={props.inComparePage}
                          providedDragHandleProps={{...provided.dragHandleProps}}
                          videoRef={props.videoRef}
                          deleteChart={() => deleteChart(index)}
                          setCurrentTime={props.setCurrentTime}
                          currentTime={currentTime}
                          key={metric}
                          trial={trial}
                          metricName={metric}
                        />
                      </li>
                    )}
                  </Draggable>
              })}
              {provided.placeholder}
            </ul>
}}
        </Droppable>
      </DragDropContext>
      {/* <div className='lower-position'>
        <button className='blue-button' onClick={addChart}>
          Add Graph
        </button>
        {reviewCharts}
      </div> */}
    </section>
  ) : null;
}

export default ReviewChartsContainer;
