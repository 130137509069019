import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function MetricNameDropdown(props) {
    const { metricNames, handleChange } = props;
    const [metricOptions, setMetricOptions] = useState([]);

    useEffect(() => {
        if(metricNames) {
            const options = metricNames.map(data => ({value: data, label: data}));
            setMetricOptions(options);
        }
    }, [metricNames])
    
    return (
        <Select
            
            placeholder={"Metric"}
            className="select-dropdown"
            options={metricOptions}
            onChange={option => handleChange(option.value)}
        />
    )
}

export default MetricNameDropdown;