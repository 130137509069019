import React, { useState, useEffect } from 'react';
import PlaybackSpeedSlider from './PlaybackSpeedSlider';
import ViewChanger from '../../videoComponents/ViewChanger';

function StreamCamera(props) {
    const { startTrial } = props;
    const [stream, setStream] = useState(null);

    useEffect(() => {
        if(startTrial) {
            fetch('/api/video_feed')
                .then(res => setStream(<img className="camera-stream" src={res.url}/>));
        }
    }, [startTrial])

    return (
        <section className="container playback-container">
            <div className="video-settings">
                {/* <PlaybackSpeedSlider/> */}
                {/* <ViewChanger videos={[]} /> */}
            </div>
            <div className="video-container">
                {stream}
            </div>
        </section>
    )
}

export default StreamCamera;