import { configureStore } from "@reduxjs/toolkit";
import chartSlice from "./chart-slice";
import notificationSlice from "./notification-slice";
import patientSlice from "./patient-slice";
import patientsSlice from "./patients-slice";
import userSlice from "./user-slice";
import videoCompare1Slice from "./compare/video-compare-1-slice";
import videoCompare2Slice from "./compare/video-compare-2-slice";
import videoSlice from "./video-slice";
const store=configureStore({
    reducer:{
        chart:chartSlice.reducer,
        patients:patientsSlice.reducer,
        patient:patientSlice.reducer,
        notification:notificationSlice.reducer,
        user:userSlice.reducer,
        video:videoSlice.reducer,
        videoCompare1:videoCompare1Slice.reducer,
        videoCompare2:videoCompare2Slice.reducer,
    }
})
export default store