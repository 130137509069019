import React from 'react';
import { Link } from 'react-router-dom';
import '../../../dist/assets/stylesheets/css/patients.css';

function PatientRow({ patient }) {
  const { patient_name, patient_condition, patient_notes, patient_id,patient_email } = patient;
  const pushUrl = `/patients/patient_dashboard/${patient_id}`;
  return (
    <tr className='patient-row'>
      <td>
        <Link to={pushUrl}>
          <div>{patient_name}</div>
        </Link>
      </td>
      <td>
        <Link to={pushUrl}>
          <div>{patient_email}</div>
        </Link>
      </td>
      <td>
        <Link to={pushUrl}>
          <div>{patient_condition}</div>
        </Link>
      </td>
      <td>
        <Link to={pushUrl}>
          <div>{patient_notes}</div>
        </Link>
      </td>
    </tr>
  );
}

export default PatientRow;