import React, { useState, useEffect } from 'react';
import { useSharedTrialData } from '../../SharedHooks';
import DisplayButtons from '../recordButtons/DisplayButtons';
import RecordTimer from './RecordTimer';

function RecordAssessment(props) {
    const { title, instructions } = props;
    const [showInstructions, setShowInstructions] = useState(false);
    const { trialData, setTrialData } = useSharedTrialData();
    const [actions, setActions] = useState({
        record: false,
        resumeRecord: false
    })

    useEffect(() => {
        const { record, resumeRecord } = actions;
        if (record) {
          // If record is true, update the component's state
          setShowInstructions(false);
        } else if (resumeRecord) {
          // If resumeRecord is true, update the component's state
          setShowInstructions(true);
        }
      }, [actions]);

    // allows user to toggle instructions
    const toggleInstructions = () => setShowInstructions(!showInstructions);

    const onChangeNotes = e => {
        setTrialData({...trialData, [title]: {...trialData[title], notes: e.target.value}});
    }

    return (
            <div>
                <div className="assessment-inputs">
                    <div onClick={toggleInstructions} className="toggable">
                        {showInstructions ? <i className="fas fa-caret-down"/> : <i className="fas fa-caret-right"/>}
                        <span>{title}</span>
                    </div>
                    <DisplayButtons title={title} actions={actions} setActions={setActions}/>
                    <RecordTimer actions={actions}/>
                </div>
                <div className="instructions" style={{display: showInstructions ? "inline-block" : "none"}}>
                    <strong>Instructions: </strong>
                    <p>{instructions}</p>
                    <strong>Notes:</strong>
                    <textarea onChange={onChangeNotes}/>
                </div>
        </div>
    )
}

export default RecordAssessment;
