import React, { Component, useEffect, useState } from 'react';
import { getValuesMetrics } from '../../util/metricsAPI';
import CanvasJSReact from "@canvasjs/react-charts";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//this version applies only to the report page

function DisplayChartv2(props) {
    const [options, setOptions] = useState({
        zoomEnabled: true,
        exportEnabled:true,
        animationEnabled: true,
        responsive: true,
        axisY: {
          title: "Degrees",
        },
        axisX: {
          title: "Time(s)",
          stripLines: [
            {
              startValue: 0,
              endValue: 0.1,
              color: "#000000",
            },
          ],
        },
        rangeChanged: function (e) {
        //   handleRangeChange(e, this);
        },
        data: [],
      });
    useEffect(()=>{
        getValuesMetrics(props.trial_id,'angles',props.name).then(res=>{
            const denominator =  (res.values.length/props.video_duration);
        const linePoints = (res.values || []).map((data, idx) => ({
          x: idx / denominator,
          y: data,
        }));
        const histogramPoints = (res.values || []).map((data) => ({ x: data }));
        // setGraphData({ linePoints, histogramPoints });
        linePoints.forEach((obj, index) => {
          obj.y = obj.y[0];
        });
        let data = [
          {
            type: "line",
            dataPoints: linePoints,
            // click: dataClickHandler,
            color: "#63C7CF",
          },
        ];
        setOptions({ ...options, data: data });

        let x_range =
          data[0].dataPoints[data[0].dataPoints.length - 1].x -
          data[0].dataPoints[0].x;
        setCursorThickness(x_range / cursor_thickness_denom);
        })
    },[])
    return <div  style={{height:'500px',width:'100%',marginTop:'10px',display:props.isSelected?'':'none'}}>
        <CanvasJSChart containerProps={{ width: '100%',maxWidth:'100%',backgroundColor:'#000000', height: '100%' }} options={options} />

    </div>;
}

export default DisplayChartv2;