import React, { Component, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PatientIDContext } from "../../Contexts";
import { Icon } from "@iconify/react";
import RecordingsTable from "./RecordingsTable";
import { getAllTrialsByUser } from "../../util/reportAPI";
import { getRecordingVideoUrl } from "../../util/recordingAPI";
import RecordingViewModal from "./RecordingViewModal";
import { useDispatch } from "react-redux";
import { notificationActions } from "../../store/notification-slice";
import RecordingsTableV2 from "./RecordingsTableV2";

function RecordingsPage() {
  const patientID = useContext(PatientIDContext);
  const [trials, setTrials] = useState()
  const [calibrationView, setCalibrationView] = useState(null);
  const dispatch = useDispatch()

  const [recordingView, setRecordingView] = useState(null)
  useEffect(() => {
    getAllTrialsByUser().then(res => setTrials(res.result))
  }, [])
  const refreshHandler = () => {
    getAllTrialsByUser().then(res => setTrials(res.result))
  }
  const handleView = (trialID) => {
    getRecordingVideoUrl(trialID).then(res => setRecordingView({ videos: res.result, trial_id: trialID })).catch((e) => {
      dispatch(
        notificationActions.setNotification({
          show: true,
          status: "error",
          message: e.response.data.reason,
        })
      );
    });
  }
  return (
    <div className="recordings-page parent-container">
      <div className="container">
        <h1 className="page-title">Recordings</h1>
        <span className="page-sub-title">
          Processed recordings will be deleted from this list within 24 hours.
        </span>
      </div>
      <PatientIDContext.Provider value={patientID}>
        <RecordingsTableV2 handleView={handleView} refreshHandler={refreshHandler} trials={trials} />
        {/* <RecordingsTable handleView={handleView} refreshHandler={refreshHandler} trials={trials} /> */}
        {recordingView && <RecordingViewModal
          handleClose={() => setRecordingView(null)}
          data={recordingView}
        />}
      </PatientIDContext.Provider>
    </div>
  );
}

export default RecordingsPage;
