import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PatientRow from "./PatientRow";
import { patientsActions, fetchPatients } from "../../store/patients-slice";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function PatientsList(props) {
  const [patients, setPatients] = useState([]);
  const [query, setQuery] = useState("");
  const [sortingQuery, setSortingQuery] = useState("patient_name");
  const [sortingAscending, setSortingAscending] = useState(true);
  const search_keys = [
    "patient_name",
    "patient_email",
    "patient_phone",
    "patient_condition",
  ];
  const patientsList = useSelector((state) => state.patients.patientsList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPatients());
  }, []);
  const search = (data) => {
    const filtered_patients = data
      .filter((patient) =>
        search_keys.some((key) =>
          patient[key]?.toLowerCase().includes(query.toLowerCase())
        )
      )
      .sort((a, b) =>
        sortingAscending
          ? a[sortingQuery].localeCompare(b[sortingQuery])
          : b[sortingQuery].localeCompare(a[sortingQuery])
      );
    return filtered_patients;
  };

  return (
    <section className="patient-table container">
      <table>
        <tbody>
          <tr>
            <td colSpan="4" style={{ height: "fit-content" }}>
              <input
                id="search-bar"
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value)}
              ></input>
            </td>
          </tr>

          <tr>
            <th>
              Name
              {sortingQuery == "patient_name" ? (
                sortingAscending ? (
                  <Icon
                    onClick={() => setSortingAscending(false)}
                    icon="fluent:arrow-sort-down-24-filled"
                  />
                ) : (
                  <Icon
                    onClick={() => setSortingAscending(true)}
                    icon="fluent:arrow-sort-down-24-filled"
                    vFlip={true}
                  />
                )
              ) : (
                <Icon
                  onClick={() => {
                    setSortingQuery("patient_name");
                    setSortingAscending(true);
                  }}
                  icon="basil:sort-solid"
                />
              )}
            </th>
            <th>
              Email
              {sortingQuery == "patient_email" ? (
                sortingAscending ? (
                  <Icon
                    onClick={() => setSortingAscending(false)}
                    icon="fluent:arrow-sort-down-24-filled"
                  />
                ) : (
                  <Icon
                    onClick={() => setSortingAscending(true)}
                    icon="fluent:arrow-sort-down-24-filled"
                    vFlip={true}
                  />
                )
              ) : (
                <Icon
                  onClick={() => {
                    setSortingQuery("patient_email");
                    setSortingAscending(true);
                  }}
                  icon="basil:sort-solid"
                />
              )}
            </th>
            <th>Condition</th>
            <th>Notes</th>
          </tr>
          {patientsList &&
            search(patientsList).map((patient) => (
              <PatientRow key={patient.patient_id} patient={patient} />
            ))}
        </tbody>
      </table>
    </section>
  );
}

export default PatientsList;
