import * as React from "react";
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { processCalib } from '../../util/trialApi';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../../../dist/assets/stylesheets/css/recordPatient.css'
import { Icon } from '@iconify/react';
import {deleteCalibration} from "../../util/recordingAPI";
import Tooltip from '@mui/material/Tooltip';

const CommonButton = styled(Button)({
    color: 'black',
    '& svg': {
        fontSize: '1.2rem',
        marginRight: '5px',
    }
});

const CircleButton = styled(Button)({
    color: 'black',
    borderRadius: '50%',
    padding: '5px',
    minWidth: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    fontSize: '1.2rem',
});

const CalibrationTable = ({ data, setCalibrations, selectCalibrationView }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);

    const handleProcessCalib = async (event, calibID) => {
        event.stopPropagation();
        const index = data.findIndex(calib => calib.id === calibID);
        if (index !== -1) {
            let copy = [...data];
            copy[index].status = 'processing';
            setCalibrations(copy);

            try {
                await processCalib(calibID);
            } catch (error) {
                console.error("Failed to process calibration", error);
            }
        }
    };

    const handleClickOpen = (id) => {
        setCurrentId(id);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const columns = [
        {
            field: "id",
            headerName: "Calibration ID",
            sortable: true,
            flex: 1
        },
        {
            field: "name",
            headerName: "Date",
            sortable: false,
            flex: 2
        },
        {
            field: "status",
            headerName: "Status",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                let color;
                let tooltipText = '';
                switch (params.value) {
                    case 'success':
                        color = "#9FD06A";
                        break;
                    case 'ready':
                        color = "#E7D859";
                        break;
                    case 'failed':
                        color = "#DD6364";
                        tooltipText = params.row.err_msg || 'Processing failed.';
                        break;
                    case 'uploading':
                        color = "#92CEFA";
                        break;
                    default:
                        color = "#BDBDBD";
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span style={{
                            height: '10px',
                            width: '10px',
                            backgroundColor: color,
                            borderRadius: '50%',
                            display: 'inline-block'
                        }}></span>
                        <span>{params.value}</span>
                        {params.value === 'failed' && (
                        <Tooltip title={tooltipText}>
                            <div style={{ cursor: 'help' }}>
                                <Icon icon="bi:info-circle-fill" />
                            </div>
                        </Tooltip>
                    )}
                </div>
                );
            },
        },
        {
            field: "score",
            headerName: "Score",
            sortable: true,
            flex: 1
        },
        {
            field: "video_count",
            headerName: "Uploaded",
            sortable: true,
            flex: 1
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 2,
            renderCell: (params) => {
                return (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display="flex" gap={2}>
                            {params.row.status === "success" || params.row.status === "ready" || params.row.status === "failed" ? (
                                <CommonButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        selectCalibrationView(params.row.id);
                                    }}
                                >
                                    <Icon icon="carbon:view" /> View
                                </CommonButton>
                            ) : null}
                            {params.row.status === "ready" ? (
                                <CommonButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleProcessCalib(e, params.row.id);
                                    }}
                                >
                                    <Icon icon="ion:push-outline" /> Process
                                </CommonButton>
                            ) : null}
                            {params.row.status === "failed" ? (
                                <CommonButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleProcessCalib(e, params.row.id);
                                    }}
                                >
                                    <Icon icon="gg:redo" /> Retry
                                </CommonButton>
                            ) : null}
                        </Box>
                        <Box>
                        </Box>
                        <Box>
                            <CircleButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickOpen(params.row.id);
                                }}
                            >
                                <Icon icon="uiw:delete" />
                            </CircleButton>
                        </Box>
                    </Box>
                );
            },
        }
    ];


    return (
        <Box
            sx={{
                "& .MuiDataGrid-root": {
                    // border: "none",
                },
                "& .MuiDataGrid-cell": {
                    // borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#EFEFEF",
                    color: "#75A7AB",
                },
                "& .MuiDataGrid-virtualScroller": {
                    // backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                    // borderTop: "none",
                    // backgroundColor: colors.blueAccent[700],
                },
                "& .MuiDataGrid-toolbarContainer": {
                    "& .MuiButton-root": {
                        // backgroundColor: "#EFEFEF",
                        // margin: "0 8px",
                        color: "#75A7AB",
                    },
                    // quickfilter style
                    '& .MuiTextField-root': {
                        // backgroundColor: 'lightgray',
                        // color: 'black',
                        // '& .MuiInputBase-input': {
                        //     color: 'blue',
                        // },
                    },
                },
            }}
            mt={2}
            mb={2}
        >
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                density="comfortable"
                sx={{
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    boxShadow: 2,
                    fontSize: '1rem',
                }}
                rowHeight={45}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10, 15, 20, 25, 30]}
                disableRowSelectionOnClick
            // slots={{ toolbar: GridToolbar }}
            // slotProps={{
            //     toolbar: {
            //         showQuickFilter: true,
            //     },
            //     panel: {
            //         sx: {
            //             "& .MuiTypography-root": {
            //                 // color: 'dodgerblue',
            //                 // fontSize: 20,
            //             },
            //             "& .MuiDataGrid-filterForm": {
            //                 // bgcolor: 'lightblue',
            //             },
            //         },
            //     },
            // }}
            />
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this calibration?
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Calibration ID: {currentId}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CommonButton onClick={() => {
                        deleteCalibration(currentId);
                        handleClose();
                    }}>
                        Delete
                    </CommonButton>
                    <CommonButton onClick={handleClose}>Cancel</CommonButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default CalibrationTable;