import React, { useState } from 'react';
import '../../../dist/assets/stylesheets/css/recordPatient.css';
import { Icon } from '@iconify/react';

function RecordingViewModal(props) {
    const [selectedView, setSelectedView] = useState(0);

    return (
        <div className='modal-container calibration-view'>
            <div className='modal'>
                <Icon onClick={props.handleClose} icon="ic:round-close" className='close-btn'/>
                <h1>Recording View</h1>
                <div className='button-container'>
                    {props.data.videos.map((video, i) => (
                        <button onClick={() => setSelectedView(i)} className={selectedView === i ? 'selected' : ''}>
                            View {i}
                        </button>
                    ))}
                </div>
                {props.data.videos.map((video, i) => (
                    <video key={i} controls muted className={selectedView === i ? 'selected' : ''} src={video}></video>
                ))}
            </div>
        </div>
    );
}

export default RecordingViewModal;
