import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function MetricTypeDropdown(props) {

    const { handleChange, metricKeys } = props;
    const [metricOptions, setMetricOptions] = useState([]);

    useEffect(() => {
        const options = Object.keys(metricKeys).map(data => ({value: data, label: data}));
        setMetricOptions(options);
    }, [metricKeys])

    return (
        <Select
            placeholder={"Type of Metric"}
            className="select-dropdown"
            options={metricOptions}
            onChange={option => handleChange(option.value)}
        />
    )
}

export default MetricTypeDropdown;